<template>
    <div>
        <transition name="fade" mode="out-in">
            <div key="loading" id="loading" class="row mb-2 d-flex justify-content-center" v-if="this.products === null">
                <APILoading :font-size='30' :text="$t('shared.apiLoading.getProducts')"/>
            </div>
            <div key="products" id="shop-product-list" v-else-if="this.products && this.products.length" class="row mb-2">
                <ShopProductPreview :key="product._id" :product="product" v-for="product in this.products"/>
                <nav class="pagination">
                    <div class="column text-left hidden-xs-down">
                        <a :disabled="pagination && pagination.page === 1"
                           class="btn btn-outline-secondary btn-sm" href="#"
                           @click.prevent="changePage(pagination.page - 1)">
                            <i class="material-icons keyboard_arrow_left"/>
                            {{$t("shop.shopProductList.previous")}}&nbsp;
                        </a>
                    </div>
                    <div class="column">
                        <ul class="pages">
                            <li v-for="(page, idx) in index" :class="{ active: page.active }" :key="idx">
                                <a @click.prevent="changePage(page.number)" v-if="page.clickable" href="#">
                                    {{page.number}}
                                </a>
                                <span v-else>{{page.number}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="column text-right hidden-xs-down">
                        <a :disabled="pagination && pagination.endPage === pagination.page"
                           class="btn btn-outline-secondary btn-sm" href="#"
                           @click.prevent="changePage(pagination.page + 1)">
                            {{$t("shop.shopProductList.next")}}&nbsp;
                            <i class="material-icons keyboard_arrow_right"/>
                        </a>
                    </div>
                </nav>
            </div>
            <div class="text-center" key="noProduct" v-else>
                <h2>{{$t("shop.shopProductList.noResults")}}</h2>
                <button @click="$emit('show-advanced-search-modal')" class="btn btn-primary mb-4">
                    <i class="fa fa-edit mr-2"/>
                    {{$t("shop.shopProductList.editMySearch")}}
                </button>
                <div class="row justify-content-center">
                    <div class='col-lg-8 col-md-10'>
                        <img alt="No product" :src="emptySVG"/>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ShopProductPreview from "./ShopProductPreview";
import APILoading from "../../../shared/Misc/APILoading";
import { importSVG } from "../../../../helpers/file";
import {mapGetters} from "vuex";

export default {
    name: "ShopProductList",
    components: {APILoading, ShopProductPreview},
    props: {
        products: {
            required: true,
        },
        pagination: {
            required: true,
        },
    },
    data() {
        return {
            index: [],
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        emptySVG() {
            return importSVG("empty", this.hermesAPIConfig.client);
        },
    },
    methods: {
        changePage(number) {
            this.$emit("change-query-param", { page: number });
        },
    },
    watch: {
        pagination() {
            if (!this.pagination) {
                return;
            }
            const page = this.pagination.page;
            this.index = [];
            this.index.push({active: true, clickable: true, number: page});
            for (let i = page - 1; i > page - 3 && i > 0; i--) {
                this.index.unshift({active: false, clickable: true, number: i});
            }
            if (page - 3 > 1) {
                this.index.unshift({active: false, clickable: false, number: "..."});
            }
            if (page - 3 >= 1) {
                this.index.unshift({active: false, clickable: true, number: 1});
            }
            for (let i = page + 1; i < page + 3 && i <= this.pagination.endPage; i++) {
                this.index.push({active: false, clickable: true, number: i});
            }
            if (page + 3 < this.pagination.endPage) {
                this.index.push({active: false, clickable: false, number: "..."});
            }
            if (page + 3 <= this.pagination.endPage) {
                this.index.push({active: false, clickable: true, number: this.pagination.endPage});
            }
        },
    },
};
</script>

<style scoped>
    #loading {
        display: flex;
        align-items: flex-start;
        height: 150vh;
    }
</style>