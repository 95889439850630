<template>
    <div id="help">
        <PageTitle :title="$t('Help.help')">
            <template v-slot:left>
                <i class="fa fa-question-circle fa-3x text-primary"></i>
            </template>
        </PageTitle>
        <div class="container padding-bottom-3x">
            <div class="row">
                <div class="col-lg-3 col-md-4">
                    <nav class="list-group">
                        <router-link class="list-group-item" :class="{active: $route.name === 'helpAll'}" to="/help">
                            <i class="fa fa-star"></i>
                            {{ $t("Help.helpAll") }}
                        </router-link>
                        <router-link v-if="frontConfig.isShopSectionEnabled" class="list-group-item"
                                     :class="{active: $route.name === 'helpShop'}" to="/help/shop">
                            <i class="fa fa-shopping-cart"></i>
                            {{ $t("Help.shop") }}
                        </router-link>
                        <router-link  class="list-group-item" :class="{active: $route.name === 'helpProfile'}" to="/help/profile">
                            <i class="fa fa-user"></i>
                            {{ $t("Help.profile") }}
                        </router-link>
                        <router-link class="list-group-item" :class="{active: $route.name === 'helpOrders'}" to="/help/orders">
                            <i class="fa fa-shopping-bag"></i>
                            {{ $t("Help.orders") }}
                        </router-link>
                        <router-link class="list-group-item" :class="{active: $route.name === 'helpOther'}" to="/help/other">
                            <i class="fa fa-question"></i>
                            {{ $t("Help.other") }}
                        </router-link>
                    </nav>
                    <div class="padding-bottom-3x hidden-md-up"></div>
                </div>
                <transition mode="out-in" name="fade">
                    <div v-if="isUserLogged === undefined" key="loading" class="col-lg-9 col-md-8">
                        <APILoading :font-size="24" :text="$t('Help.loadingQuestions')"/>
                    </div>
                    <router-view v-else key="help" class="col-lg-9 col-md-8"/>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../shared/Misc/PageTitle";
import APILoading from "../../shared/Misc/APILoading";

export default {
    name: "Help",
    components: {APILoading, PageTitle},
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
        }),
    },
};
</script>

<style scoped>

</style>