<template>
    <div id="product-options-list">
        <ul class="text-sm text-left">
            <li v-if="product.getOption('assisted-product-creation')">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductOptionsList.assistedProductCreation')}:`"/>
                <span v-html="$t('ProductOptionsList.whatIsAssistedProductCreation')"/>
            </li>
            <li v-if="product.getOption('physical-printing-approval')">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductOptionsList.physicalPrintingApproval')}:`"/>
                <span v-html="$t('ProductOptionsList.whatIsPhysicalPrintingApproval')"/>
            </li>
        </ul>
    </div>
</template>

<script>
import Product from "@/classes/Product";

export default {
    name: "ProductOptionsList",
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>