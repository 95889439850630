<template>
    <div id="self-publishing-flow-quote">
        <div class="row">
            <div class="col-12 row align-items-center text-center">
                <i class="fa fa-book-open fa-3x text-primary col-1"/>
                <div class="col-11">
                    <h5 class="mb-0" v-html="$t('SelfPublishingFlowMetadata.fillBookMetadata')"/>
                </div>
            </div>
        </div>
        <hr class="mt-3 mb-3"/>
        <ValidationObserver ref="FormObserver" v-slot="{ invalid }">
            <form @submit.prevent>
                <div class="row">
                    <div class="col-md-6">
                        <ValidationProvider v-slot="{ errors, classes }">
                            <div class="form-group">
                                <label for="book-title">
                                    <span v-html="$t('SelfPublishingFlowMetadata.title')"/>
                                    <span class="red-text ml-1" v-html="'*'"/>
                                </label>
                                <input :placeholder="$t('SelfPublishingFlowMetadata.required')" class="form-control"
                                       id="book-title" v-model="localSelfPublishedBook.title" required :class="classes"
                                       maxlength="50" ref="titleInput"/>
                                <div class="invalid-feedback" v-html="errors[0]"/>
                                <InputAvailableCharacters :input-value="localSelfPublishedBook.title" :max-length="50"
                                                          class="text-muted font-italic text-sm ml-2"/>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider ref="authorValidationProvider" v-slot="{ errors, classes }">
                            <div class="form-group">
                                <label for="book-author">
                                    <span v-html="$t('SelfPublishingFlowMetadata.author')"/>
                                    <span class="red-text ml-1" v-html="'*'"/>
                                </label>
                                <input :placeholder="$t('SelfPublishingFlowMetadata.required')" class="form-control"
                                       id="book-author" v-model="localSelfPublishedBook.author" required :class="classes"
                                       maxlength="50">
                                <div class="invalid-feedback" v-html="errors[0]"/>
                                <InputAvailableCharacters :input-value="localSelfPublishedBook.author" :max-length="50"
                                                          class="text-muted font-italic text-sm ml-2"/>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <hr class="mt-3 mb-5"/>
                <div class="row align-items-center">
                    <div class="col-md-6 text-center text-lg">
                        <div id="add-to-cart-container" class="custom-control custom-checkbox custom-control-inline">
                            <input class="custom-control-input" type="checkbox" id="add-to-cart"
                                   v-model="addToCart"/>
                            <label class="custom-control-label c-pointer text-less-bold text-primary" for="add-to-cart"
                                   v-html="$t('SelfPublishingFlowMetadata.directlyAddToCart')"/>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <SubmitBtn :text="$t('SelfPublishingFlowMetadata.create')" @click="createBook" :disabled="!isStepValid || invalid"
                                   classes="btn btn-primary m-0 btn-block" class="text-lg" :loading="loadingCreateProduct"/>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Product from "../../../../../classes/Product";
import {mapGetters} from "vuex";
import InputAvailableCharacters from "../../../../shared/Forms/InputAvailableCharacters";
import SubmitBtn from "../../../../shared/Misc/SubmitBtn";

export default {
    name: "SelfPublishingFlowMetadata",
    components: {SubmitBtn, InputAvailableCharacters},
    props: {
        selfPublishedBook: {
            type: Product,
            required: true,
        },
        loadingCreateProduct: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            localSelfPublishedBook: new Product(this.selfPublishedBook),
            addToCart: true,
        };
    },
    computed: {
        ...mapGetters("user", {
            userFullName: "fullName",
        }),
        isStepValid() {
            return true;
        },
    },
    async mounted() {
        if (!this.selfPublishedBook.quantity) {
            return await this.$router.push("/self-publishing/create-book");
        }
        if (this.localSelfPublishedBook.title) {
            this.$refs.FormObserver.validate();
        } else if (!this.localSelfPublishedBook.author) {
            this.localSelfPublishedBook.author = this.userFullName;
            await this.$nextTick();
            this.$refs.authorValidationProvider.validate();
            this.$refs.titleInput.focus();
        }
    },
    methods: {
        createBook() {
            const metadata = {
                title: this.localSelfPublishedBook.title,
                author: this.localSelfPublishedBook.author,
            };
            this.$emit("create-book", { metadata, addToCart: this.addToCart });
        },
    },
};
</script>

<style lang="scss" scoped>
    #add-to-cart-container {
        label {
            font-size: 1rem;
        }
    }
</style>