<template>
    <div id="gutenberg-home-disclaimer">
        <div class="container-fluid pl-0">
            <section class="row">
                <div class="col-7">
                    <img id="gutenberg-disclaimer-img" :src="gutenbergDisclaimerIMG" alt="Gutenberg&Co"/>
                </div>
                <div class="col-5 text-center">
                    <div class="m-3">
                        <h2 v-html="'Comment ça marche ?'"/>
                        <iframe height="200" src="https://www.youtube.com/embed/rnC0xes7mq4" class="shadow-lg"
                                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen id="gutenberg-disclaimer-video"/>
                        <ol class="text-left">
                            <li v-html="'Je choisis un livre dans la boutique.'"/>
                            <li v-html="'Le robot l\'imprime et le relie en 5 minutes.'"/>
                            <li v-html="'Je le récupère en librairie.'"/>
                        </ol>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { importPNG } from "../../../../../helpers/file";

export default {
    name: "GutenbergHomeDisclaimer",
    data() {
        return {
            gutenbergDisclaimerIMG: importPNG("home-disclaimer", "gutenberg"),
        };
    },
};
</script>

<style scoped>
    #home-disclaimer-gutenberg {
        max-width: 100%;
        border-bottom: 1px solid lightgrey;
    }

    #gutenberg-disclaimer-video {
        border: 1px solid lightgrey;
    }

    #gutenberg-disclaimer-img {
        border-right: 1px solid lightgrey;
    }
</style>