<template>
    <div id="profile">
        <PageTitle :title='selfPublishingTitle'>
            <template v-slot:left>
                <i class="fa text-primary fa-3x" :class="selfPublishingTitleIcon"/>
            </template>
        </PageTitle>
        <div class="container-fluid padding-bottom-3x mb-2">
            <transition name="fadein" mode="out-in">
                <div class="row">
                    <div class="col-lg-2">
                        <nav class="list-group">
                            <router-link to="/self-publishing/create-book"
                                         class="list-group-item d-flex align-items-center"
                                         :class="{ active: inSelfPublishingFlow(this.$route.name) }">
                                <i class="fas fa-pen-fancy mr-2"/>
                                {{ $t("SelfPublishingCreateBook.title") }}
                            </router-link>
                            <router-link to="/self-publishing/books"
                                         class="list-group-item d-flex align-items-center"
                                         :class="{ active: $route.name === 'selfPublishingBooks' }">
                                <i class="fa fa-book-open mr-2"/>
                                {{ $t("SelfPublishingBooks.title") }}
                            </router-link>
                            <router-link to="/profile/orders" target="_blank" class="list-group-item d-flex align-items-center">
                                <i class="fa fa-list-alt mr-2"/>
                                {{ $t("SelfPublishing.myOrders") }}
                            </router-link>
                        </nav>
                    </div>
                    <div class="col-lg-10">
                        <router-view class="fadeIn"/>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import PageTitle from "../../shared/Misc/PageTitle";
import { inSelfPublishingFlow } from "../../../helpers/section";

export default {
    name: "SelfPublishing",
    components: { PageTitle },
    computed: {
        selfPublishingTitle() {
            const titles = {
                selfPublishingBooks: this.$t("SelfPublishingBooks.title"),
                selfPublishingCreateBook: this.$t("SelfPublishingCreateBook.title"),
                selfPublishingFlowQuote: this.$t("SelfPublishingCreateBook.title"),
                selfPublishingFlowMetadata: this.$t("SelfPublishingCreateBook.title"),
            };
            return titles[this.$route.name];
        },
        selfPublishingTitleIcon() {
            const icons = {
                selfPublishingBooks: "fa-book-open",
                selfPublishingCreateBook: "fa-pen-fancy",
                selfPublishingFlowQuote: "fa-pen-fancy",
                selfPublishingFlowMetadata: "fa-pen-fancy",
            };
            return icons[this.$route.name];
        },
    },
    methods: {
        inSelfPublishingFlow,
    },
};
</script>

<style scoped>

</style>