<template>
    <div class="col-lg-8">
        <div class="padding-top-2x mt-2 hidden-lg-up"></div>
        <transition mode="out-in" name="fade">
            <div key="loading" class="row align-items-center justify-content-center" id="loading-order"
                 v-if="orders === null">
                <APILoading :font-size="30" :text="$t('shared.apiLoading.getYourOrders')"/>
            </div>
            <div key="no-order" v-else-if="!orders.length">
                <div class="row">
                    <div class="col-12 text-center">
                        <h2 class="text-center">{{$t("profile.orders.noOrder")}}</h2>
                        <router-link v-if="frontConfig.isShopSectionEnabled" class="btn btn-primary" to="/shop">
                            {{$t("profile.orders.checkShop")}}
                        </router-link>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <img alt="No orders" :src="noOrderSVG"/>
                    </div>
                </div>
            </div>
            <div key="orders" v-else>
                <v-client-table :data="orders" :columns="columns" :options="options">
                    <a slot="reference" slot-scope="props" href="#" class="text-medium navi-link" @click="showDetailsModal(props.row)">
                        #{{ props.row.reference }}
                    </a>
                    <div class="text-sm" slot="createdAt" slot-scope="props">
                        {{properDate(props.row.createdAt)}}
                    </div>
                    <div class="text-center text-sm" slot="status" slot-scope="props">
                        <OrderProductionStatusLabel :order="props.row"/>
                    </div>
                    <span class="text-center text-sm" slot="payment" slot-scope="props">
                        <OrderPaymentStatusLabel :order="props.row"/>
                    </span>
                    <div slot="actions" slot-scope="props" class="align-middle justify-content-center text-center">
                        <button v-if="props.row.status.payment === 'waiting' || props.row.status.payment === 'error'"
                                v-tooltip="$t('ProfileOrdersLine.changePayment')"
                                @click="showChangePaymentModal(props.row)" class="btn btn-info btn-xs m-1">
                        <span class="fa-stack">
                            <i class="fas fa-dollar-sign fa-stack-1x"/>
                            <i id="redo-icon" class="fas fa-redo-alt fa-stack-2x"/>
                        </span>
                        </button>
                        <button @click="showDetailsModal(props.row)" v-tooltip="$t('ProfileOrdersLine.showReview')"
                                class="btn btn-primary btn-xs m-1">
                            <i class="fa fa-list-ul"/>
                        </button>
                        <DownloadBilling text="<i class='fa fa-download'></i>" :order="props.row" :tooltip-text="$t('ProfileOrdersLine.downloadBilling')"
                                         classes="btn btn-danger btn-xs m-1" v-if="props.row.billing.method !== 'agreement'"/>
                    </div>
                </v-client-table>
                <ProfileOrdersDetailsModal @update-order="updateOrder" ref="orderDetailsModal"/>
                <ProfileOrdersChangePaymentModal @update-order="updateOrder" ref="changePaymentModal"/>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Order from "../../../../classes/Order";
import APILoading from "../../../shared/Misc/APILoading";
import ProfileOrdersDetailsModal from "./ProfileOrdersDetailsModal";
import ProfileOrdersChangePaymentModal from "./ProfileOrdersChangePaymentModal";
import OrderProductionStatusLabel from "../../../shared/Order/OrderProductionStatusLabel";
import DownloadBilling from "../../../shared/Order/DownloadBilling";
import { properDate } from "../../../../helpers/date";
import { properPrice } from "../../../../helpers/price";
import { importSVG } from "../../../../helpers/file";
import OrderPaymentStatusLabel from "../../../shared/Order/OrderPaymentStatusLabel";
import {decodeJWTToken} from "../../../../helpers/API";

export default {
    name: "ProfileOrders",
    components: {
        OrderPaymentStatusLabel,
        DownloadBilling,
        OrderProductionStatusLabel,
        ProfileOrdersChangePaymentModal,
        ProfileOrdersDetailsModal,
        APILoading,
    },
    data() {
        return {
            orders: null,
            columns: ["reference", "createdAt", "status", "payment", "actions"],
            options: {
                headings: {
                    reference: this.$t("profile.orders.reference"),
                    createdAt: this.$t("profile.orders.date"),
                    status: this.$t("profile.orders.status"),
                    payment: this.$t("profile.orders.payment"),
                    actions: this.$t("profile.orders.actions"),
                },
                perPage: 10,
                sortable: ["reference", "createdAt", "status", "totalWT"],
                filterable: ["reference", "createdAt", "status", "totalWT"],
                filterAlgorithm: {
                    reference(row, query) {
                        const { proForma, bill, credit } = row.billing.reference;
                        return row.reference.includes(query) ||
                            proForma && proForma.includes(query) ||
                            bill && bill.includes(query) ||
                            credit && credit.includes(query);
                    },
                },
            },
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
            hermesAPIConfig: "hermesAPI",
        }),
        noOrderSVG() {
            return importSVG("no_order", this.hermesAPIConfig.client);
        },
    },
    created() {
        this.getOrders();
    },
    methods: {
        properDate, properPrice,
        async getOrders() {
            try {
                const { userId } = decodeJWTToken();
                const response = await this.$hermesAPI.getOrders({ user: userId });
                this.orders = [];
                for (let i = 0; i < response.data.length; i++) {
                    this.orders.push(new Order(response.data[i]));
                }
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        showDetailsModal(order) {
            this.$refs.orderDetailsModal.show(order);
        },
        showChangePaymentModal(order) {
            this.$refs.changePaymentModal.show(order);
        },
        updateOrder(newOrder) {
            const idx = this.orders.findIndex(order => order._id === newOrder._id);
            this.orders.splice(idx, 1, newOrder);
        },
    },
};
</script>

<style lang="scss">
    ul.pagination {
        margin-top: 5px;
    }

    td {
        vertical-align: middle !important;
    }
</style>