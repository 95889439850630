<template>
    <transition name="fade" mode="out-in">
        <APILoading key="loading" v-if="carriers === null || this.addressSelected._id === 0" :font-size="24"
                    :text="$t('CheckoutShippingHome.loadingCarriers')"/>
        <div class="text-center" key="no-carriers" v-else-if="carriers.length === 0">
            <h4 class="text-center text-muted">{{ $t("CarrierList.noCarriersForYourOrderYet") }}</h4>
            <h5 class="text-center text-muted">{{ $t("CarrierList.contactOurCustomerService") }}</h5>
            <router-link to="/contact" target="_blank" class="btn btn-primary">
                {{ $t("CarrierList.contact") }}
            </router-link>
        </div>
        <div v-else key="carriers" class="table-responsive">
            <table class="table table-hover">
                <thead class="thead-default">
                <tr>
                    <th/>
                    <th>{{$t('CheckoutShippingHome.carrier')}}</th>
                    <th>{{$t('checkout.shipping.priceWT')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(carrier, idx) in carriers" class="carrier" @click="selectCarrier(carrier)" :key="carrier.id"
                    v-tooltip="carrierTooltip(carrier)">
                    <td class="align-middle">
                        <div v-if="cantSelectCarrierBcOfGLN(carrier)">
                            <i class="fa fa-exclamation-triangle fa-2x text-warning"/>
                        </div>
                        <div v-else class="custom-control custom-radio mb-0">
                            <input :value="carrier.id" :id="`carrier-${idx}`"
                                   class="custom-control-input" type="radio" name="carrier">
                            <label class="custom-control-label" :for="`carrier-${idx}`"/>
                        </div>
                    </td>
                    <td class="align-middle">
                        <i v-if="isCarrierAgreement(carrier)" class="mr-2 fa fa-handshake text-primary"/>
                        <span v-if="isPrivateCarrier(carrier)" class="mr-2 text-success">
                            [{{ $t("CarrierList.custom") }}]
                        </span>
                        <span class="text-medium">{{carrier.name}}</span>
                    </td>
                    <td class="align-middle">{{getPrice(carrier)}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Carrier from "../../../../../classes/Carrier";
import APILoading from "../../../../shared/Misc/APILoading";
import { properPrice } from "../../../../../helpers/price";

export default {
    name: "CarrierList",
    components: {APILoading},
    props: {
        addressSelected: {
            required: true,
        },
        carrierIdSelected: {
            required: true,
        },
        order: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            carriers: null,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            currency: "currency",
        }),
        ...mapGetters("user", {
            userCart: "cart",
            userCarrierAgreements: "carrierAgreements",
            userPrivateCarriers: "privateCarriers",
        }),
    },
    methods: {
        properPrice,
        hasDeliveryReducedTax() {
            const productsWithReducedTax = this.order.products.filter(product => product.tax.reduced === true);
            const productsWithFullTax = this.order.products.filter(product => product.tax.reduced === false);
            const totalProductsWithReducedTax = productsWithReducedTax.reduce((acc, product) => acc + product.priceWT, 0);
            const totalProductsWithFullTax = productsWithFullTax.reduce((acc, product) => acc + product.priceWT, 0);
            return totalProductsWithFullTax < totalProductsWithReducedTax;
        },
        async getPublicCarriers(request) {
            const response = await this.$diceAPI.getCarriers(request);
            return response.data.map(carrier => new Carrier(carrier));
        },
        async getPrivateCarriers(request) {
            const promises = [];
            const privateCarriers = [];
            if (!this.userPrivateCarriers.length) {
                return promises;
            }
            for (const privateCarrier of this.userPrivateCarriers) {
                promises.push(this.$diceAPI.getCarrier({ params: { id: privateCarrier }, queries: request }).then(response => {
                    privateCarriers.push(new Carrier(response.data));
                }).catch(() => {
                    console.log(`The private carrier with ID ${privateCarrier} can't handle this delivery`);
                }));
            }
            await Promise.all(promises);
            return privateCarriers;
        },
        async getCarriers() {
            try {
                this.carriers = null;
                this.privateCarriers = null;
                const deliveryReducedTax = this.hasDeliveryReducedTax();
                const packageWeight = this.order.products.reduce((acc, product) => acc + product.weight * product.quantity, 0);
                const packageProductsAmount = this.order.products.reduce((acc, product) => acc + product.quantity, 0);
                const request = {
                    "delivery-country": this.addressSelected.country.iso,
                    "package-products-quantity": packageProductsAmount,
                    "package-weight": packageWeight,
                    "reduced-tax": deliveryReducedTax,
                    "needs-gln": this.hermesAPIConfig.shop.type === "B2C" ? false : undefined,
                    currency: this.currency,
                };
                const promises = await Promise.all([this.getPublicCarriers(request), this.getPrivateCarriers(request)]);
                const privateCarriers = [];
                for (const privateCarrier of promises[1]) {
                    if (!promises[0].find(publicCarrier => publicCarrier.id === privateCarrier.id)) {
                        privateCarriers.push(privateCarrier);
                    }
                }
                this.carriers = [...promises[0], ...privateCarriers];
                if (this.order.delivery.method === "home" && this.carrierId !== 0) {
                    const selectedCarrier = this.carriers.find(carrier => carrier.id === this.carrierIdSelected);
                    if (selectedCarrier) {
                        this.selectCarrier(selectedCarrier);
                    } else {
                        this.$emit("reset-carrier-id");
                    }
                }
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        isCarrierAgreement(carrier) {
            return this.userCarrierAgreements.includes(carrier.id);
        },
        isPrivateCarrier(carrier) {
            return this.userPrivateCarriers.includes(carrier.id) && carrier.private;
        },
        carrierTooltip(carrier) {
            if (this.cantSelectCarrierBcOfGLN(carrier)) {
                return this.$t("CheckoutShippingHome.youNeedGLNToSelectCarrier");
            } else if (this.isCarrierAgreement(carrier)) {
                return this.$t("CheckoutShippingHome.youGotCarrierAgreement");
            } else {
                return null;
            }
        },
        getPrice(carrier) {
            if (this.isCarrierAgreement(carrier)) {
                return this.$t("CheckoutShippingHome.offered");
            }
            return this.properPrice(carrier.request.priceWT, carrier.currency, this.currencies);
        },
        selectCarrier(carrier) {
            if (!this.cantSelectCarrierBcOfGLN(carrier)) {
                this.$emit("select-carrier", carrier);
            }
        },
        cantSelectCarrierBcOfGLN(carrier) {
            return carrier && carrier.needsGLN && (!this.addressSelected.gln || this.addressSelected.gln.trim() === "");
        },
    },
    watch: {
        addressSelected() {
            this.getCarriers();
        },
        userCart() {
            if (this.userCart && this.userCart.length) {
                this.getCarriers();
            }
        },
    },
};
</script>

<style scoped>
    .carrier {
        cursor: pointer;
    }
</style>