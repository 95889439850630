<template>
    <nav class="site-menu">
        <ul>
            <li v-if="frontConfig.isHomeSectionEnabled" id="main-nav-home" :class="{ active: $route.name === 'home' }">
                <router-link to="/">
                    <span v-html="$t('header.mainNav.home')"/>
                </router-link>
            </li>
            <li v-if="frontConfig.isShopSectionEnabled" id="main-nav-shop" :class="{ active: inShopSection }">
                <router-link to="/shop">
                    <span v-html="shopSectionText"/>
                </router-link>
                <ul class="sub-menu" v-if="frontConfig.nav.showShopSubmenu && this.publishers && this.publishers.length > 1">
                    <li class="text-center">
                        <router-link to="/shop">
                            <span>{{ $t("header.mainNav.fullShop") }}</span>
                        </router-link>
                    </li>
                    <hr class="mt-1 mb-1"/>
                    <li class="text-center text-muted text-sm mb-1">{{ $t("header.mainNav.filterBy") }}</li>
                    <hr v-if="this.publishersA.length"/>
                    <li v-if="this.publishersA.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "A", to: "C" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersA">
                                <router-link :to="`/shop?publisher=${publisher._id}`">{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersD.length"/>
                    <li v-if="this.publishersD.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "D", to: "F" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersD">
                                <router-link :to="`/shop?publisher=${publisher._id}`">{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersG.length"/>
                    <li v-if="this.publishersG.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "G", to: "I" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersG">
                                <router-link :to="`/shop?publisher=${publisher._id}`">{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersJ.length"/>
                    <li v-if="this.publishersJ.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "J", to: "L" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersJ">
                                <router-link :to="`/shop?publisher=${publisher._id}`">{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersM.length"/>
                    <li v-if="this.publishersM.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "M", to: "O" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersM">
                                <router-link :to="`/shop?publisher=${publisher._id}`">{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersP.length"/>
                    <li v-if="this.publishersP.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "P", to: "R" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-200">
                            <li :key="publisher._id" v-for="publisher in publishersP">
                                <router-link :to='`/shop?publisher=${publisher._id}`'>{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersS.length"/>
                    <li v-if="this.publishersS.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "S", to: "U" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersS">
                                <router-link :to="`/shop?publisher=${publisher._id}`">{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersV.length"/>
                    <li v-if="this.publishersV.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "V", to: "X" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersV">
                                <router-link :to='`/shop?publisher=${publisher._id}`'>{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <hr v-if="this.publishersY.length"/>
                    <li v-if="this.publishersY.length" class="has-children">
                        <a class="pb-3 pt-3" href="#"><span>{{ $t("header.mainNav.publishersFromTo", { from: "Y", to: "Z" }) }}</span></a>
                        <ul class="sub-menu publisher-menu w-300">
                            <li :key="publisher._id" v-for="publisher in publishersY">
                                <router-link :to="`/shop?publisher=${publisher._id}`">{{publisher.name}}</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <ul class="sub-menu" v-else-if="this.publishers && this.publishers.length === 0">
                    <APILoading :icon-size="30" :font-size='12' :text="$t('shared.apiLoading.getPublishers')"/>
                </ul>
            </li>
            <transition name="fade">
                <li v-if="frontConfig.isSelfPublishingSectionEnabled && isSelfPublishingSystemEnabled"
                    id="main-nav-self-publishing" key="self-publishing" :class="{ active: inSelfPublishingSection }">
                    <router-link to='/self-publishing'>
                        <span v-html="$t('header.mainNav.printMyBook')"/>
                    </router-link>
                    <ul class="sub-menu w-270">
                        <li :class="{ active: inSelfPublishingFlow }">
                            <router-link to="/self-publishing/create-book">
                                <span>{{ $t("header.mainNav.createBook") }}</span>
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'selfPublishingBooks' }">
                            <router-link to="/self-publishing/books">
                                <span>{{ $t("header.mainNav.myBooks") }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </transition>
            <transition name="fade">
                <li v-if="frontConfig.isCartSectionEnabled && user.isCartEnabled" id="main-nav-user-cart"
                    key="cart" :class="{ active: $route.name === 'cart' }">
                    <router-link to='/cart'>
                        <span>{{$t("header.mainNav.cart")}}</span>
                    </router-link>
                </li>
            </transition>
            <transition name="fade">
                <li v-if="frontConfig.isCheckoutSectionEnabled && isUserLogged && userCart && userCart.length" id="main-nav-checkout"
                    key="checkout" :class="{ active: inCheckoutSection }">
                    <router-link to='/checkout'><span>{{$t("header.mainNav.checkout")}}</span></router-link>
                </li>
            </transition>
            <transition name="fade">
                <li v-if="frontConfig.isProfileSectionEnabled && isUserLogged" id="main-nav-profile" key="profile"
                    :class="{ active: inProfileSection }">
                    <router-link to='/profile'><span>{{$t("header.mainNav.profile")}}</span></router-link>
                    <ul class="sub-menu w-270">
                        <li :class="{ active: $route.name === 'profileOrders' }">
                            <router-link to="/profile/orders">
                                <span>{{ $t("header.mainNav.myOrders") }}</span>
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'me' }">
                            <router-link to="/profile">
                                <span>{{ $t("header.mainNav.myProfile") }}</span>
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'profileAddresses' }">
                            <router-link to="/profile/addresses">
                                <span>{{ $t("header.mainNav.myAddresses") }}</span>
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'profilePreferences' }">
                            <router-link to="/profile/preferences">
                                <span>{{ $t("header.mainNav.myPreferences") }}</span>
                            </router-link>
                        </li>
                        <li :class="{ active: $route.name === 'profileChangePassword' }">
                            <router-link to="/profile/change-password">
                                <span>{{ $t("header.mainNav.changeMyPassword") }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </transition>
            <HelpTab/>
        </ul>
    </nav>
</template>

<script>
import { mapGetters } from "vuex";
import APILoading from "../../shared/Misc/APILoading";
import { inProfileSection, inShopSection, inSelfPublishingSection, inSelfPublishingFlow, inCheckoutSection } from "@/helpers/section";
import HelpTab from "@/components/shared/Header/HelpTab";

export default {
    name: "MainNav",
    components: {HelpTab, APILoading},
    computed: {
        ...mapGetters("config", {
            frontConfig: "front",
            isSelfPublishingSystemEnabled: "isSelfPublishingSystemEnabled",
        }),
        ...mapGetters("publishers", {
            publishers: "publishers",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
            userCart: "cart",
        }),
        shopSectionText() {
            return this.$t(`header.mainNav.${this.frontConfig.app.i18n.terms.shopSection}`);
        },
        inProfileSection() {
            return inProfileSection(this.$route.name);
        },
        inShopSection() {
            return inShopSection(this.$route.name);
        },
        inSelfPublishingSection() {
            return inSelfPublishingSection(this.$route.name);
        },
        inSelfPublishingFlow() {
            return inSelfPublishingFlow(this.$route.name);
        },
        inCheckoutSection() {
            return inCheckoutSection(this.$route.name);
        },
        publishersA() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "a" && firstLetter <= "c";
            });
        },
        publishersD() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "d" && firstLetter <= "f";
            });
        },
        publishersG() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "g" && firstLetter <= "i";
            });
        },
        publishersJ() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "j" && firstLetter <= "l";
            });
        },
        publishersM() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "m" && firstLetter <= "o";
            });
        },
        publishersP() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "p" && firstLetter <= "r";
            });
        },
        publishersS() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "s" && firstLetter <= "u";
            });
        },
        publishersV() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "v" && firstLetter <= "x";
            });
        },
        publishersY() {
            return !this.publishers ? [] : this.publishers.filter(p => {
                const firstLetter = p.name.charAt(0).toLowerCase();
                return firstLetter >= "y";
            });
        },
    },
    methods: {
        generatePublisherMenusMaxWidth() {
            this.$nextTick(() => {
                const publisherMenus = document.getElementsByClassName("publisher-menu");
                for (const publisherMenu of publisherMenus) {
                    publisherMenu.setAttribute("style", `max-height: ${window.innerHeight - publisherMenu.getBoundingClientRect().top - 25}px`);
                }
            });
        },
    },
    watch: {
        publishers(publishers) {
            if (publishers && publishers.length) {
                this.generatePublisherMenusMaxWidth();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .publisher-menu {
        overflow-y: scroll !important;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }
        &::-webkit-scrollbar-thumb {
            background: #e1e1e1;
            border: 0 none #ffffff;
            border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: grey;
        }
        &::-webkit-scrollbar-thumb:active {
            background: dimgrey;
        }
        &::-webkit-scrollbar-track {
            background: white;
            border: 0 none #ffffff;
            border-radius: 50px;
        }
        &::-webkit-scrollbar-track:hover {
            background: white;
        }
        &::-webkit-scrollbar-track:active {
            background: white;
        }
        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
</style>