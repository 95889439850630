<template>
    <div class="tab-pane fade" id="signup" role="tabpanel">
        <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent='register' autocomplete="off" id="signup-form">
                <div class="mb-3">
                    <label for="email">
                        {{$t('header.account.registerPanel.email')}}
                        <span class="red-text">*</span>
                    </label>
                    <ValidationProvider v-slot="{ errors, classes }">
                        <div class="input-group">
                            <input class="form-control" :class="classes"
                                   type="email" required v-model='user.email' id="email" ref="email"
                                   :placeholder="$t('header.account.registerPanel.required')">
                            <span class="input-group-addon"><i class="material-icons mail"></i></span>
                        </div>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
                <div class="mb-3" v-if="shop.type === 'B2B'">
                    <label for="society">
                        {{$t('header.account.registerPanel.society')}}
                        <span class="red-text">*</span>
                    </label>
                    <ValidationProvider v-slot="{ errors, classes }">
                        <div class="input-group">
                            <input class="form-control" type="text" :placeholder="$t('header.account.registerPanel.required')"
                                   required :class="classes" v-model='user.society' id="society">
                            <span class="input-group-addon"><i class="material-icons business_center"></i></span>
                        </div>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
                <div v-else-if="shop.type === 'B2C'">
                    <div class="mb-3">
                        <label for="first-name">
                            {{$t('header.account.registerPanel.firstName')}}
                            <span class="red-text">*</span>
                        </label>
                        <ValidationProvider v-slot="{ errors, classes }">
                            <div class="input-group">
                                <input class="form-control" type="text" :placeholder="$t('header.account.registerPanel.required')"
                                       required v-model='user.firstName' :class="classes" id="first-name">
                                <span class="input-group-addon"><i class="material-icons person"></i></span>
                            </div>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="mb-3">
                        <label for="last-name">
                            {{$t('header.account.registerPanel.lastName')}}
                            <span class="red-text">*</span>
                        </label>
                        <ValidationProvider v-slot="{ errors, classes }">
                            <div class="input-group">
                                <input class="form-control" type="text" :placeholder="$t('header.account.registerPanel.required')"
                                       required v-model='user.lastName' :class="classes" id="last-name">
                                <span class="input-group-addon"><i class="material-icons person"></i></span>
                            </div>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <ValidationObserver>
                    <div class="mb-3">
                        <label for="register-password">
                            {{$t('header.account.registerPanel.password')}}
                            <span class="red-text">*</span>
                        </label>
                        <ValidationProvider rules="min:5" vid="confirmation" v-slot="{ errors, classes }">
                            <div class="input-group">
                                <input class="form-control" id="register-password" type="password" v-model='user.password' minlength='5'
                                       :placeholder="$t('header.account.registerPanel.required')" required :class="classes">
                                <span class="input-group-addon"><i class="material-icons lock"></i></span>
                            </div>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="mb-3">
                        <label for="confirm-password">
                            {{$t('header.account.registerPanel.confirmPassword')}}
                            <span class="red-text">*</span>
                        </label>
                        <ValidationProvider rules="min:5|confirm_password:confirmation" v-slot="{ errors, classes }">
                            <div class="input-group">
                                <input class="form-control" id="confirm-password" type="password" v-model='confirmPassword' minlength='5'
                                       :placeholder="$t('header.account.registerPanel.required')" required :class="classes">
                                <span class="input-group-addon"><i class="material-icons lock"></i></span>
                            </div>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                </ValidationObserver>
                <SubmitBtn :classes="'btn btn-primary btn-block'" :disabled="invalid" :loading="loading"
                            :text="$t('header.account.registerPanel.register')"/>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";
import SubmitBtn from "../../../shared/Misc/SubmitBtn";
import User from "../../../../classes/User";

export default {
    name: "RegisterPanel",
    components: {SubmitBtn},
    data() {
        return {
            user: new User(),
            confirmPassword: undefined,
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
            shop: "shop",
        }),
    },
    methods: {
        ...mapActions("sideBarMenu", {
            hideSideBar: "hide",
        }),
        async register() {
            if (this.user.password !== this.confirmPassword) {
                return this.$toasted.error(this.$t("header.account.registerPanel.passwordsDontMatch"), {icon: "key"});
            }
            try {
                this.loading = true;
                const response = await this.$hermesAPI.register(this.user);
                this.resetForm();
                this.showSwalMessage(response);
            } catch (err) {
                this.handleRegisterError(err.response.data);
            } finally {
                this.loading = false;
            }
        },
        showSwalMessage(response) {
            if (response.data.method === "admin") {
                this.hideSideBar();
                Swal.fire({
                    icon: "success",
                    title: this.$t("header.account.registerPanel.registerSuccess"),
                    text: this.$t("header.account.registerPanel.waitForAdminToActivate"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("header.account.registerPanel.gotIt"),
                    footer: `<span>${this.$t("header.account.registerPanel.dontForgetSpam")}</span>`,
                });
            } else if (response.data.method === "mail") {
                Swal.fire({
                    icon: "success",
                    title: this.$t("header.account.registerPanel.registerSuccess"),
                    text: this.$t("header.account.registerPanel.waitForActivationByMail"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("header.account.registerPanel.gotIt"),
                    footer: `<span>${this.$t("header.account.registerPanel.dontForgetSpam")}</span>`,
                });
                this.$emit("open-login-tab");
            }
        },
        resetForm() {
            this.user = new User();
            this.confirmPassword = "";
        },
        handleRegisterError(error) {
            if (error.code === 3) {
                this.$refs.email.focus();
                this.$toasted.error(this.$t("header.account.registerPanel.emailUsed"), { icon: "envelope" });
            } else {
                this.$toasted.error(this.$t("shared.toast.badRequest"), { icon: "server" });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #signup-form {
        label {
            font-weight: 500;
            span {
                font-size: 0.9rem;
                color: red;
            }
        }
    }
</style>