<template>
    <transition name="fade" mode="out-in">
        <div id="loading-order" class="row justify-content-center align-items-center" key="loading"
             v-if="order._id === undefined || !isUserLogged">
            <APILoading :font-size="30" :text="$t('shared.apiLoading.getYourOrder')"/>
        </div>
        <div key="checkoutComplete" v-else id="checkout-complete">
            <PageTitle :title="$t('CheckoutComplete.title')" class="mb-4"/>
            <div class="container padding-bottom-3x mb-2">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-8">
                        <img alt="Checkout complete" id="checkout-complete-svg" :src="checkoutCompleteSVG"/>
                    </div>
                    <div class="col-12 text-center mt-4">
                        <h2>
                            <i class="fa fa-check-circle text-success"/>
                            {{$t("CheckoutComplete.orderSuccessful")}}
                        </h2>
                        <h2 v-if="order.status.payment === 'error'">
                            <i class="fas fa-exclamation-triangle text-warning"/>
                            {{$t("CheckoutComplete.orderPaymentFailed")}}
                        </h2>
                        <h3 v-else-if="order.status.payment === 'waiting'">{{$t("CheckoutComplete.dontForgetToPay")}}</h3>
                        <h3 v-if="order.selfPublishedProductsWithFilesToUpload.length">
                            <i class="fas fa-exclamation-triangle text-warning"/>
                            {{$t("CheckoutComplete.someProductsNeedFilesUpload")}}
                        </h3>
                        <a href="#" v-if="canPayBill" v-scroll-to="'#how-to-pay-bill'" class="btn btn-warning">
                            {{$t("CheckoutComplete.payMyBill")}}
                        </a>
                        <a href="#" v-if="order.selfPublishedProductsWithFilesToUpload.length" v-scroll-to="'#checkout-complete-upload-files'" class="btn btn-warning">
                            <i class="fa fa-cloud-upload-alt"/>
                            {{$t("CheckoutComplete.uploadMyFiles")}}
                        </a>
                        <h2 v-if="order.status.payment === 'error'">
                            <i class="fas fa-exclamation-triangle text-warning"/>
                            {{$t("CheckoutComplete.orderPaymentFailed")}}
                        </h2>
                        <DownloadBilling :order="order" classes="btn btn-danger" v-if="order.billing.method !== 'agreement'"
                                         :display-tooltip="false"/>
                        <router-link class="btn btn-primary" to="/profile/orders">
                            {{$t('CheckoutComplete.checkOrders')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <CheckoutCompletePayBill v-if="canPayBill" :order="order" id="how-to-pay-bill" class="mb-5"/>
            <CheckoutCompleteUploadFiles v-if="order.selfPublishedProductsWithFilesToUploadOrToCheck.length" class="mb-4" :order="order"
                                         @update-product="updateProduct"/>
        </div>
    </transition>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import { mapGetters, mapActions } from "vuex";
import PageTitle from "../../shared/Misc/PageTitle";
import CheckoutCompletePayBill from "./CheckoutCompletePayBill/CheckoutCompletePayBill";
import Order from "../../../classes/Order";
import Product from "../../../classes/Product";
import APILoading from "../../shared/Misc/APILoading";
import DownloadBilling from "../../shared/Order/DownloadBilling";
import { importSVG } from "@/helpers/file";
import CheckoutCompleteUploadFiles from "./CheckoutCompleteUploadFiles/CheckoutCompleteUploadFiles";

export default {
    name: "CheckoutComplete",
    components: {CheckoutCompleteUploadFiles, DownloadBilling, APILoading, CheckoutCompletePayBill, PageTitle},
    data() {
        return {
            order: new Order(),
        };
    },
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        checkoutCompleteSVG() {
            return importSVG("checkout_complete", this.hermesAPIConfig.client);
        },
        canPayBill() {
            return this.order.status.payment === "waiting" || this.order.status.payment === "error";
        },
    },
    async created() {
        if (this.$route.query.order === undefined) {
            return this.$router.push("/");
        }
        return !this.isUserLogged ? await this.checkUserAuthentication() : await this.getOrder();
    },
    methods: {
        ...mapActions("user", {
            checkUserAuthentication: "checkUserAuthentication",
        }),
        async getOrder() {
            try {
                const response = await this.$hermesAPI.getOrder(this.$route.query.order);
                this.order = new Order(response.data);
                if (this.$route.query.scroll === "how-to-pay-bill") {
                    setTimeout(() => {
                        VueScrollTo.scrollTo("#how-to-pay-bill");
                    }, 1000);
                }
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        updateProduct(updatedProduct) {
            const idx = this.order.products.findIndex(({ _id }) => _id === updatedProduct._id);
            if (idx !== -1) {
                this.order.products.splice(idx, 1, new Product(updatedProduct));
            }
        },
    },
    watch: {
        isUserLogged: {
            handler(isLogged) {
                if (!isLogged) {
                    this.checkUserAuthentication();
                } else {
                    this.getOrder();
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>

    #loading-order {
        height: 30vh;
    }

</style>