<template>
    <div id="home">
        <GutenbergHome v-if="hermesAPIConfig.client === 'gutenberg'"/>
        <ILPHome v-else-if="hermesAPIConfig.client === 'ilp'"/>
        <PrintCorpHome v-else-if="hermesAPIConfig.client === 'imprimer-vos-livres'"/>
        <LaBoutiqueSoBookHome v-else-if="hermesAPIConfig.client === 'la-boutique-sobook'"/>
        <DefaultHome v-else/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultHome from "./DefaultHome/DefaultHome";
import ILPHome from "./CustomHome/ILPHome/ILPHome";
import GutenbergHome from "./CustomHome/GutenbergHome/GutenbergHome";
import PrintCorpHome from "./CustomHome/PrintCorpHome/PrintCorpHome";
import LaBoutiqueSoBookHome from "./CustomHome/LaBoutiqueSoBookHome/LaBoutiqueSoBookHome";

export default {
    name: "Home",
    components: {LaBoutiqueSoBookHome, PrintCorpHome, GutenbergHome, ILPHome, DefaultHome },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
    },
};
</script>

<style scoped>

</style>