<template>
    <div class="col-lg-8">
        <div class="padding-top-2x mt-2 hidden-lg-up"></div>
        <ValidationObserver ref="formObserver" v-slot="{ invalid }">
            <form @submit.prevent="updateUser">
                <div class="row">
                    <div class="col-md-6">
                        <ValidationProvider rules="people_name" v-slot="{ errors, classes }">
                            <div class="form-group">
                                <label for="account-fn">{{$t("profile.me.firstname")}} <span>*</span></label>
                                <input :placeholder="$t('profile.me.required')" class="form-control" type="text" id="account-fn"
                                       v-model="localUser.firstName" required :class="classes">
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="people_name" v-slot="{ errors, classes }">
                            <div class="form-group">
                                <label for="account-ln">{{$t("profile.me.lastname")}} <span>*</span></label>
                                <input :placeholder="$t('profile.me.required')" class="form-control" type="text" id="account-ln"
                                       v-model="capitalizedLastName" required :class="classes">
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <ValidationProvider v-slot="{ errors, classes }">
                            <div class="form-group">
                                <label for="account-email">{{$t("profile.me.email")}}</label>
                                <input class="form-control" type="email" id="account-email" :value="localUser.email"
                                       disabled :class="classes">
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider rules="mobile_phone" v-slot="{ errors, classes }">
                            <div class="form-group">
                                <label for="account-mobile-phone">{{$t("profile.me.mobilePhone")}}</label>
                                <input class="form-control" type="tel" id="account-mobile-phone" v-model="localUser.mobilePhone"
                                       :placeholder="$t('profile.me.optional')" :class="classes">
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div v-if="shop.type === 'B2B'" class="row">
                    <div class="col-md-6">
                        <ValidationProvider v-slot="{ errors, classes }">
                            <div class="form-group">
                                <label for="account-society">{{$t("profile.me.society")}} <span>*</span></label>
                                <input class="form-control" type="text" id="account-society" v-model="localUser.society"
                                       :placeholder="$t('profile.me.required')" :class="classes" required>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr class="mt-2 mb-3">
                        <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <SubmitBtn :disabled="invalid" :classes="'btn btn-primary margin-right-none'" :loading="loading"
                                        :text="$t('profile.me.update')"/>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitBtn from "../../shared/Misc/SubmitBtn";
import User from "../../../classes/User";

export default {
    name: "Me",
    components: {SubmitBtn},
    props: {
        user: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
            localUser: new User(this.user),
        };
    },
    computed: {
        ...mapGetters("user", {
            userLastName: "lastName",
        }),
        ...mapGetters("config", {
            shop: "shop",
        }),
        capitalizedLastName: {
            set(value) {
                this.localUser.lastName = value.toUpperCase();
            },
            get() {
                return this.localUser.lastName ? this.localUser.lastName.toUpperCase() : "";
            },
        },
    },
    mounted() {
        this.$refs.formObserver.validate();
    },
    methods: {
        async updateUser() {
            try {
                this.loading = true;
                await this.$store.dispatch("user/updateUser", { user: this.localUser });
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .form-group {
        label {
            span {
                color: red;
            }
        }
    }
</style>