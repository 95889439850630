<template>
    <div id="self-publishing-flow-files">
        <div id="form-content" class="row align-items-center">
            <transition mode="out-in" name="fade">
                <div v-if="status === 'done'" key="files-uploaded" class="col-12">
                    <div v-if="!product.alreadyOrdered" class="row align-items-center">
                        <div class="col-2">
                            <img :src="addToCartSVG" alt="Add to cart"/>
                        </div>
                        <div class="col-10 align-items-center text-left">
                            <h3 v-html="$t('UploadProductFiles.orderYourProductToStartChecks')"/>
                        </div>
                    </div>
                    <div v-else class="row align-items-center">
                        <div class="col-2">
                            <img :src="mailboxSVG" alt="Mail box"/>
                        </div>
                        <div class="col-10 align-items-center text-left">
                            <h3 v-html="$t('UploadProductFiles.watchForMailbox')"/>
                            <p class="text-muted font-italic mb-0" v-html="$t('UploadProductFiles.ifNotPrintable')"/>
                        </div>
                    </div>
                    <hr class="my-2"/>
                    <div class="row justify-content-center">
                        <div class="col-6">
                            <button class="btn btn-block btn-info" @click="status = 'pending'">
                                <i class="fa fa-cloud-download-alt mr-2"/>
                                <span v-html="$t('UploadProductFiles.sendOtherFiles')"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else-if="product._id && status === 'pending' || status === 'errored'" id="file-inputs" key="file-inputs" class="col-12">
                    <form @submit.prevent="sendProductFiles">
                        <hr class="my-4"/>
                        <div class="form-group row align-items-center">
                            <div class="col-4">
                                <ProductFileStatusBadge class="w-100" book-part="corpus" :product="product"/>
                            </div>
                            <div class="col-8">
                                <div class="custom-file">
                                    <input ref="CorpusFileInput" @change="checkFileInput('corpus')"
                                           class="custom-file-input" :disabled="!product.canUploadCorpusFile"
                                           type="file" accept="application/pdf" id="book-corpus-file"
                                           v-tooltip="corpusFileInputTooltip"/>
                                    <label class="custom-file-label" for="book-corpus-file"
                                           v-html="this.corpusFileInputLabel"/>
                                </div>
                                <div v-if="product.files.corpus.status === 'uploaded'"
                                     class="badge badge-warning mt-2">
                                    <i class="fa fa-exclamation-triangle mr-2"/>
                                    <span v-html="$t('UploadProductFiles.fileWillBeReplaced')"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-center">
                            <div class="col-4">
                                <ProductFileStatusBadge class="w-100" book-part="cover" :product="product"/>
                            </div>
                            <div class="col-8">
                                <div class="custom-file">
                                    <input ref="CoverFileInput" @change="checkFileInput('cover')"
                                           class="custom-file-input" :disabled="!product.canUploadCoverFile"
                                           type="file" accept="application/pdf" id="book-cover-file"
                                           v-tooltip="coverFileInputTooltip"/>
                                    <label class="custom-file-label" for="book-cover-file"
                                           v-html="this.coverFileInputLabel"/>
                                </div>
                                <div v-if="product.files.cover.status === 'uploaded'"
                                     class="badge badge-warning mt-2">
                                    <i class="fa fa-exclamation-triangle mr-2"/>
                                    <span v-html="$t('UploadProductFiles.fileWillBeReplaced')"/>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-4 mb-4"/>
                        <div class="row justify-content-center">
                            <SubmitBtn classes="btn btn-primary" :loading="false" :disabled="!cover.file && !corpus.file"
                                       :text="$t('UploadProductFiles.uploadFiles')"/>
                        </div>
                    </form>
                </div>
                <div v-else-if="status === 'uploading'" id="upload-progress-bar" key="upload-progress-bar" class="col-12">
                    <h3 class="text-center">
                        <img width="100" alt="Uploading..." :src="GIFs.uploadToCloud"/>
                        <span v-html="$t('UploadProductFiles.uploadingFiles')"/>
                    </h3>
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                             :aria-valuenow="progress.percent" aria-valuemin="0" aria-valuemax="100"
                             :style="`width: ${progress.percent}%`"/>
                    </div>
                    <h4 class="text-center text-muted mt-3 text-monospace" v-html="progressText"/>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitBtn from "../../Misc/SubmitBtn";
import uploadToCloud from "@/assets/gif/upload-to-cloud.gif";
import Product from "@/classes/Product";
import { importSVG } from "@/helpers/file";
import ProductFileStatusBadge from "@/components/shared/Product/ProductFiles/ProductFileStatusBadge";

export default {
    name: "UploadProductFiles",
    components: {ProductFileStatusBadge, SubmitBtn},
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    data() {
        return {
            cover: {
                file: null,
            },
            corpus: {
                file: null,
            },
            status: "pending",
            progress: { percent: 0 },
            GIFs: {
                uploadToCloud,
            },
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        coverFileInputLabel() {
            return this.cover.file ? this.cover.file.name : this.$t("UploadProductFiles.chooseFile");
        },
        corpusFileInputLabel() {
            return this.corpus.file ? this.corpus.file.name : this.$t("UploadProductFiles.chooseFile");
        },
        progressText() {
            return this.progress.percent !== 100 ? `${this.progress.percent}%` : this.$t("UploadProductFiles.finalizing");
        },
        mailboxSVG() {
            return importSVG("mailbox", this.hermesAPIConfig.client);
        },
        addToCartSVG() {
            return importSVG("add_to_cart", this.hermesAPIConfig.client);
        },
        coverFileInputTooltip() {
            if (this.product.canUploadCoverFile) {
                return this.$t("UploadProductFiles.fileMustBePDFAndNotTooLarge");
            } else {
                return this.$t("UploadProductFiles.fileCantBeUploadedAnymore");
            }
        },
        corpusFileInputTooltip() {
            if (this.product.canUploadCorpusFile) {
                return this.$t("UploadProductFiles.fileMustBePDFAndNotTooLarge");
            } else {
                return this.$t("UploadProductFiles.fileCantBeUploadedAnymore");
            }
        },
    },
    methods: {
        reset() {
            this.cover.file = null;
            this.corpus.file = null;
            this.status = "pending";
            this.progress.percent = 0;
        },
        checkFileInput(bookPart) {
            const refInput = bookPart === "cover" ? "CoverFileInput" : "CorpusFileInput";
            const files = this.$refs[refInput].files;
            if (!files.length) {
                return this[bookPart].file = null;
            } else {
                const file = files[0];
                try {
                    if (file.type !== "application/pdf") {
                        throw this.$t("UploadProductFiles.chosenFileNotPDF");
                    } else if (file.size > 104857600) {
                        throw this.$t("UploadProductFiles.chosenFileTooBig");
                    }
                    this[bookPart].file = file;
                } catch (e) {
                    this.$toasted.error(e, {icon: "times"});
                    this[bookPart].file = null;
                }
            }
        },
        async sendProductFiles() {
            try {
                this.status = "uploading";
                this.$emit("uploading-files");
                const { data } = await this.$hermesAPI.sendSelfPublishedProductFiles(this.product._id, {
                    corpus: this.corpus,
                    cover: this.cover,
                }, this.progress);
                this.status = "done";
                this.$emit("files-uploaded", data);
                this.$toasted.success(this.$t("UploadProductFiles.filesUploaded"), { icon: "check" });
            } catch (e) {
                this.$error.displayError(e);
                this.$emit("upload-error");
                this.status = "errored";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #form-content {
        min-height: 175px;
    }

    .badge {
        white-space: normal;
        padding: 10px;
    }
</style>