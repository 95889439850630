var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contact-default"}},[_c('div',{staticClass:"row justify-content-center align-items-center padding-bottom-2x mb-2",attrs:{"id":"contact-informations"}},[_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-12 display-3 text-center"},[_c('i',{staticClass:"fa fa-headset mr-3 text-primary"}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('Contact.customerService'))}}),_c('hr',{staticClass:"mt-3 mb-3"})])]),_c('div',{staticClass:"col-2 text-center"},[_c('img',{attrs:{"alt":"BusinessMan","src":_vm.businessManSVG}})]),_c('div',{staticClass:"col-8 text-center"},[_c('ul',{staticClass:"list-icon",attrs:{"id":"contact-list"}},[(_vm.shop.mail)?_c('li',[_c('span',{staticClass:"fa fa-envelope text-muted mr-2"}),_c('a',{staticClass:"navi-link",attrs:{"target":"_blank","href":("mailto:" + (_vm.shop.mail))},domProps:{"innerHTML":_vm._s(_vm.shop.mail)}})]):_vm._e(),(_vm.shop.phone)?_c('li',[_c('span',{staticClass:"fa fa-phone text-muted mr-2"}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.shop.phone)}})]):_vm._e()])]),_c('div',{staticClass:"col-2"})]),(_vm.shop.mail)?_c('div',{attrs:{"id":"contact-send-mail"}},[_c('ValidationObserver',{ref:"FormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendMail($event)}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"display-3 text-center"},[_c('i',{staticClass:"fab fa-telegram-plane mr-3 text-primary"}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('Contact.sendMail'))}})]),_c('hr',{staticClass:"mt-3 mb-3"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('ValidationProvider',{ref:"senderNameValidationProvider",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"send-mail-sender-name"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('ContactDefault.yourName'))}}),_c('RedAsterisk')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.name),expression:"sender.name"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('ContactDefault.required'),"id":"send-mail-sender-name","required":"","disabled":_vm.loading},domProps:{"value":(_vm.sender.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-4"},[_c('ValidationProvider',{ref:"senderMailValidationProvider",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"send-mail-sender-mail"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('ContactDefault.yourMail'))}}),_c('RedAsterisk')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.mail),expression:"sender.mail"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('ContactDefault.required'),"id":"send-mail-sender-mail","type":"email","required":"","disabled":_vm.loading},domProps:{"value":(_vm.sender.mail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "mail", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"col-4"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"send-mail-sender-phone"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('ContactDefault.yourPhone'))}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sender.phone),expression:"sender.phone"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('ContactDefault.optional'),"id":"send-mail-sender-phone","type":"tel","disabled":_vm.loading},domProps:{"value":(_vm.sender.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.sender, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{ref:"mailSubjectValidationProvider",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"send-mail-mail-subject"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('ContactDefault.mailSubject'))}}),_c('RedAsterisk')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mail.subject),expression:"mail.subject"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('ContactDefault.required'),"id":"send-mail-mail-subject","required":"","disabled":_vm.loading},domProps:{"value":(_vm.mail.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail, "subject", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationProvider',{ref:"mailContentValidationProvider",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"send-mail-mail-content"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('ContactDefault.mailContent'))}}),_c('RedAsterisk')],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.mail.content),expression:"mail.content"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('ContactDefault.required'),"id":"send-mail-mail-content","required":"","rows":"5","disabled":_vm.loading},domProps:{"value":(_vm.mail.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.mail, "content", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 text-center"},[_c('SubmitBtn',{attrs:{"text":_vm.$t('ContactDefault.sendMail'),"loading":_vm.loading,"disabled":invalid,"classes":"btn btn-primary"}})],1)])])])])]}}],null,false,625627576)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }