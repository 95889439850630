<template>
    <div class="site-branding">
        <router-link class="site-logo hidden-xs-down" to="/">
            <img id="logo" :src="logo" alt="Logo">
        </router-link>
        <router-link class="site-logo logo-sm hidden-sm-up" to="/">
            <img :src="smallLogo" alt="Logo">
        </router-link>
        <!--<div class="lang-currency-switcher">-->
            <!--<div class="lang-currency-toggle"><img :src="GBFlag" alt="English"><span>USD</span><i-->
                    <!--class="material-icons arrow_drop_down"></i>-->
                <!--<ul class="lang-currency-dropdown">-->
                    <!--<li><a href="#"><img :src="FRFlag" alt="Français">Français</a></li>-->
                <!--</ul>-->
            <!--</div>-->
        <!--</div>-->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { importPNG } from "@/helpers/file";

export default {
    name: "Branding",
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        logo() {
            return importPNG("logo", this.hermesAPIConfig.client);
        },
        smallLogo() {
            return importPNG("logo-sm", this.hermesAPIConfig.client);
        },
    },
};
</script>

<style lang="scss" scoped>

    .site-branding {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        #logo {
            max-height: 60px;
            width: auto;
        }
    }
</style>