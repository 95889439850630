<template>
    <div>
        <div v-if="order.billing.method !== 'agreement'">
            <CheckoutStep id="payment-step" :text="$t('CheckoutPayment.IWantToPay')" icon="hand-holding-usd"/>
            <ul class="nav nav-tabs d-flex justify-content-center" role="tablist">
                <li v-if="isPayPalPaymentEnabled" class="nav-item">
                    <a class="nav-link" href="#checkout-payment-paypal" :class="{ active: order.billing.method === 'paypal' }"
                       data-toggle="tab" role="tab">
                        <img class="mr-2" alt="PayPal Logo" width="20" :src="paypalLogo"/>
                        <span v-html="$t('CheckoutPayment.byPayPal')"/>
                    </a>
                </li>
                <li v-if="isCreditCardPaymentEnabled" class="nav-item">
                    <a class="nav-link" href="#checkout-payment-credit-card" data-toggle="tab"
                       role="tab" :class="{ active: order.billing.method === 'creditCard' }">
                        <i class="fa fa-credit-card mr-2"/>
                        <span v-html="$t('CheckoutPayment.byCreditCard')"/>
                    </a>
                </li>
                <li v-if="isOrangeMoneyPaymentEnabled" class="nav-item">
                    <a class="nav-link" href="#checkout-payment-orange-money" data-toggle="tab"
                       role="tab" :class="{ active: order.billing.method === 'orangeMoney' }">
                        <img class="mr-2" alt="Orange Money Logo" width="20" :src="orangeMoneyLogo"/>
                        <span v-html="$t('CheckoutPayment.byOrangeMoney')"/>
                    </a>
                </li>
                <li v-if="isWariPaymentEnabled" class="nav-item">
                    <a class="nav-link" href="#checkout-payment-wari" data-toggle="tab"
                       role="tab" :class="{ active: order.billing.method === 'wari' }">
                        <img class="mr-2" alt="Wari Logo" width="20" :src="wariLogo"/>
                        <span v-html="$t('CheckoutPayment.byWari')"/>
                    </a>
                </li>
                <li v-if="isBankTransferPaymentEnabled" class="nav-item">
                    <a class="nav-link" href="#checkout-payment-bank-transfer" data-toggle="tab"
                       role="tab" :class="{ active: order.billing.method === 'bankTransfer' }">
                        <i class="fa fa-university mr-2"/>
                        <span v-html="$t('CheckoutPayment.byBankTransfer')"/>
                    </a>
                </li>
                <li v-if="isCheckPaymentEnabled" class="nav-item">
                    <a class="nav-link" href="#checkout-payment-check" data-toggle="tab"
                       role="tab" :class="{ active: order.billing.method === 'check' }">
                        <i class="fas fa-money-check mr-2"/>
                        <span v-html="$t('CheckoutPayment.byCheck')"/>
                    </a>
                </li>
                <li v-if="isCashPaymentEnabled" class="nav-item">
                    <a class="nav-link" href="#checkout-payment-cash" data-toggle="tab"
                       role="tab" :class="{ active: order.billing.method === 'cash' }">
                        <i class="fas fa-money-bill mr-2"/>
                        <span v-html="$t('CheckoutPayment.byCash')"/>
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <CheckoutPaymentPayPal v-if="isPayPalPaymentEnabled" :preferences="preferences" :general-infos="generalInfos"
                                       :order="order" @create-order="createOrder"/>
                <CheckoutPaymentCreditCard v-if="isCreditCardPaymentEnabled" :preferences="preferences" :general-infos="generalInfos"
                                           :order="order" @set-billing-method="setBillingMethod"/>
                <CheckoutPaymentOrangeMoney v-if="isOrangeMoneyPaymentEnabled" :preferences="preferences" :general-infos="generalInfos"
                                            :order="order" @set-billing-method="setBillingMethod"/>
                <CheckoutPaymentWari v-if="isWariPaymentEnabled" :preferences="preferences" :general-infos="generalInfos"
                                     :order="order" @set-billing-method="setBillingMethod"/>
                <CheckoutPaymentBankTransfer v-if="isBankTransferPaymentEnabled" :preferences="preferences" :general-infos="generalInfos"
                                             :order="order" @set-billing-method="setBillingMethod"/>
                <CheckoutPaymentCheck v-if="isCheckPaymentEnabled" :preferences="preferences" :general-infos="generalInfos"
                                      :order="order" @set-billing-method="setBillingMethod"/>
                <CheckoutPaymentCash v-if="isCashPaymentEnabled" :preferences="preferences" :general-infos="generalInfos"
                                     :order="order" @set-billing-method="setBillingMethod"/>
            </div>
        </div>
        <div v-else>
            <CheckoutPaymentAgreement @set-billing-method="setBillingMethod"/>
        </div>
        <div class="d-flex justify-content-between pt-4 mt-2">
            <a @click.prevent="$emit('change-step', 2)" class="btn btn-outline-secondary m-0">{{$t('checkout.back')}}</a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckoutPaymentOrangeMoney from "./CheckoutPaymentOrangeMoney";
import CheckoutPaymentWari from "./CheckoutPaymentWari";
import CheckoutPaymentBankTransfer from "./CheckoutPaymentBankTransfer";
import CheckoutPaymentCash from "./CheckoutPaymentCash";
import CheckoutPaymentPayPal from "./CheckoutPaymentPayPal";
import CheckoutPaymentAgreement from "./CheckoutPaymentAgreement";
import CheckoutPaymentCheck from "./CheckoutPaymentCheck";
import CheckoutPaymentCreditCard from "./CheckoutPaymentCreditCard";
import CheckoutStep from "../../../shared/Order/CheckoutStep";
import { properPrice } from "@/helpers/price";

export default {
    name: "CheckoutPayment",
    components: {
        CheckoutStep,
        CheckoutPaymentCreditCard,
        CheckoutPaymentCheck,
        CheckoutPaymentAgreement,
        CheckoutPaymentPayPal,
        CheckoutPaymentCash,
        CheckoutPaymentBankTransfer,
        CheckoutPaymentWari,
        CheckoutPaymentOrangeMoney,
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        preferences: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paypalLogo: require("../../../../assets/img/payments/paypal.png"),
            orangeMoneyLogo: require("../../../../assets/img/payments/orangeMoney.png"),
            wariLogo: require("../../../../assets/img/payments/wari.png"),
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("config", {
            shop: "shop",
            isCashPaymentEnabled: "isCashPaymentEnabled",
            isCheckPaymentEnabled: "isCheckPaymentEnabled",
            isBankTransferPaymentEnabled: "isBankTransferPaymentEnabled",
            isOrangeMoneyPaymentEnabled: "isOrangeMoneyPaymentEnabled",
            isWariPaymentEnabled: "isWariPaymentEnabled",
            isCreditCardPaymentEnabled: "isCreditCardPaymentEnabled",
            isPayPalPaymentEnabled: "isPayPalPaymentEnabled",
        }),
        generalInfos() {
            return {
                society: this.shop.displayedName.toUpperCase(),
                amount: this.properPrice(this.order.totalWT, this.order.currency, this.currencies),
            };
        },
    },
    created() {
        if (this.order.delivery.addressId === "0") {
            return this.$emit("change-step", 0);
        }
    },
    methods: {
        properPrice,
        setBillingMethod(billingMethod) {
            this.$emit("set-billing-method", billingMethod);
        },
        createOrder(order) {
            this.$emit("create-order", order);
        },
    },
};
</script>

<style lang="scss" scoped>
    #payment-step {
        margin-bottom: 0 !important;
    }

    .nav-link:not(.active) {
        img {
            filter: grayscale(1);
        }
    }
</style>