import Publisher from "./Publisher";
import Config from "../../config";
import { hasProp } from "@/helpers/class";
import ProductReview from "./ProductReview";
import User from "./User";

class Product {
    constructor(product = null) {
        this._id = !hasProp(product, "_id") ? 0 : product._id;
        this.source = !hasProp(product, "source") ? undefined : product.source;
        this.publisher = !hasProp(product, "publisher") ? new Publisher() : new Publisher(product.publisher);
        this.selfPublisher = !hasProp(product, "selfPublisher") ? new User() : new User(product.selfPublisher);
        this.isbn = !hasProp(product, "isbn") ? undefined : product.isbn;
        this.title = !hasProp(product, "title") ? undefined : product.title;
        this.subtitle = !hasProp(product, "subtitle") ? undefined : product.subtitle;
        this.price = !hasProp(product, "price") ? 0 : product.price;
        this.priceWT = !hasProp(product, "priceWT") ? 0 : product.priceWT;
        this.currency = !hasProp(product, "currency") ? "EUR" : product.currency;
        this.description = !hasProp(product, "description") ? undefined : product.description.replace(/\r?\n/g, "<br/>");
        this.pod = !hasProp(product, "pod") ? undefined : product.pod;
        this.width = !hasProp(product, "width") ? undefined : product.width;
        this.height = !hasProp(product, "height") ? undefined : product.height;
        this.dimensions = {
            orientation: !hasProp(product, "dimensions.orientation") ? undefined : product.dimensions.orientation,
            width: !hasProp(product, "dimensions.width") ? undefined : product.dimensions.width,
            height: !hasProp(product, "dimensions.height") ? undefined : product.dimensions.height,
            thickness: !hasProp(product, "dimensions.thickness") ? undefined : product.dimensions.thickness,
        };
        this.weight = !hasProp(product, "weight") ? undefined : Math.round(product.weight);
        this.author = !hasProp(product, "author") ? "" : product.author;
        this.paging = {
            total: !hasProp(product, "paging.total") ? undefined : product.paging.total,
            color: !hasProp(product, "paging.color") ? undefined : product.paging.color,
            black: !hasProp(product, "paging.black") ? undefined : product.paging.black,
        };
        this.bookCollection = !hasProp(product, "bookCollection") ? undefined : product.bookCollection;
        this.clil = !hasProp(product, "clil") ? undefined : parseInt(product.clil);
        this.firstCoverPhotoUrl = !hasProp(product, "firstCoverPhotoUrl") ? "" : product.firstCoverPhotoUrl;
        this.fourthCoverPhotoUrl = !hasProp(product, "fourthCoverPhotoUrl") ? "" : product.fourthCoverPhotoUrl;
        this.releaseDate = !hasProp(product, "releaseDate") ? 0 : product.releaseDate;
        this.createdAt = !hasProp(product, "createdAt") ? 0 : product.createdAt;
        this.updatedAt = !hasProp(product, "updatedAt") ? 0 : product.updatedAt;
        this.quantity = !hasProp(product, "quantity") ? undefined : product.quantity;
        this.total = !hasProp(product, "total") ? 0 : product.total;
        this.totalWT = !hasProp(product, "totalWT") ? 0 : product.totalWT;
        this.active = {
            value: !hasProp(product, "active.value") ? undefined : product.active.value,
            reason: !hasProp(product, "active.reason") ? undefined : product.active.reason,
            updatedAt: !hasProp(product, "active.updatedAt") ? undefined : product.active.updatedAt,
        };
        this.discountedPrice = !hasProp(product, "discountedPrice") ? this.priceWT : product.discountedPrice;
        this.discountedPriceWT = !hasProp(product, "discountedPriceWT") ? this.priceWT : product.discountedPriceWT;
        this.discount = !hasProp(product, "discount") ? 0 : product.discount;
        this.alreadyOrdered = !hasProp(product, "alreadyOrdered") ? undefined : product.alreadyOrdered;
        this.reviews = product && product.reviews ? product.reviews.map(review => new ProductReview(review)) : [];
        this.tax = {
            reduced: !hasProp(product, "tax.reduced") ? undefined : product.tax.reduced,
            percent: !hasProp(product, "tax.percent") ? undefined : product.tax.percent,
        };
        this.reducedTax = !hasProp(product, "reducedTax") ? undefined : product.reducedTax;
        this.options = !hasProp(product, "options") ? [] : product.options.map(option => option);
        this.components = {
            binding: {
                id: !hasProp(product, "components.binding.id") ? undefined : product.components.binding.id,
                name: !hasProp(product, "components.binding.name") ? undefined : product.components.binding.name,
            },
            cover: {
                printing: {
                    bothSides: !hasProp(product, "components.cover.printing.bothSides") ? undefined : product.components.cover.printing.bothSides,
                },
                paper: {
                    id: !hasProp(product, "components.cover.paper.id") ? undefined : product.components.cover.paper.id,
                    name: !hasProp(product, "components.cover.paper.name") ? undefined : product.components.cover.paper.name,
                },
                lamination: {
                    id: !hasProp(product, "components.cover.lamination.id") ? undefined : product.components.cover.lamination.id,
                    name: !hasProp(product, "components.cover.lamination.name") ? undefined : product.components.cover.lamination.name,
                },
            },
            corpus: {
                printing: {
                    id: !hasProp(product, "components.corpus.printing.id") ? undefined : product.components.corpus.printing.id,
                    name: !hasProp(product, "components.corpus.printing.name") ? undefined : product.components.corpus.printing.name,
                },
                paper: {
                    id: !hasProp(product, "components.corpus.paper.id") ? undefined : product.components.corpus.paper.id,
                    name: !hasProp(product, "components.corpus.paper.name") ? undefined : product.components.corpus.paper.name,
                },
            },
        };
        this.files = {
            cover: {
                status: !hasProp(product, "files.cover.status") ? undefined : product.files.cover.status,
                reason: !hasProp(product, "files.cover.reason") ? undefined : product.files.cover.reason,
            },
            corpus: {
                status: !hasProp(product, "files.corpus.status") ? undefined : product.files.corpus.status,
                reason: !hasProp(product, "files.corpus.reason") ? undefined : product.files.corpus.status,
            },
            printingApprovedByClient: !hasProp(product, "files.printingApprovedByClient") ? undefined : product.files.printingApprovedByClient,
        };
        this.geographicalAreas = !hasProp(product, "geographicalAreas") ? [] : product.geographicalAreas.map(geographicalArea => parseInt(geographicalArea));
        this.countries = !hasProp(product, "countries") ? [] : product.countries.map(country => country);
    }

    hasDisplayedPrices(userLogged) {
        const showPricesNotLogged = Config.products.showPriceNotLogged;
        return (showPricesNotLogged || userLogged === true) && this.publisher.showPrice === true;
    }

    canAddToCart(userLogged, doesShopShowOnlyPODProducts, cannotOrderUnprintableTwice) {
        const canAddToCartNotLogged = Config.products.canAddToCartNotLogged;
        return this.publisher.showPrice === true && (canAddToCartNotLogged || userLogged) &&
            this.active.value && (!doesShopShowOnlyPODProducts || this.pod) &&
            (cannotOrderUnprintableTwice && !this.alreadyOrdered || this.pod);
    }

    averageRate() {
        return parseFloat((this.reviews.reduce((acc, review) => acc + review.rate, 0) / this.reviews.length).toFixed(1));
    }

    isAlreadyReviewed(user) {
        return !!this.reviews.find(review => review.user._id === user._id);
    }

    getOption(optionName) {
        return this.options.find(({ name }) => name === optionName);
    }

    get formatInMM() {
        return `${this.dimensions.width} mm x ${this.dimensions.height} mm`;
    }

    get canUploadCoverFile() {
        const uploadFileStatuses = ["to-upload", "uploaded", "errored"];
        return uploadFileStatuses.includes(this.files.cover.status);
    }

    get canUploadCorpusFile() {
        const uploadFileStatuses = ["to-upload", "uploaded", "errored"];
        return uploadFileStatuses.includes(this.files.corpus.status);
    }

    get canUploadFiles() {
        return this.canUploadCorpusFile || this.canUploadCoverFile;
    }

    get waitingForUserPrintingApproval() {
        return this.files.cover.status === "printable" && this.files.corpus.status === "printable" &&
            !this.files.printingApprovedByClient && !!this.getOption("physical-printing-approval");
    }
}

export default Product;