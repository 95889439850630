<template>
    <div class="toolbar-section" :class="{ 'current': this.isSearchSectionActive }" id="header-search-panel">
        <div id="advanced-search-disclaimer" class="mt-4" key="advanced-search-disclaimer">
            <p class="text-muted font-italic mb-2" v-html="$t('HeaderSearch.welcomeInRapidSearch')"/>
            <a @click.prevent="openAdvancedSearch" href="#" v-html="$t('HeaderSearch.iNeedMoreAdvancedSearch')"/>
        </div>
        <hr class="mt-2"/>
        <form class="search-form" @submit.prevent="">
            <input type="search" v-model="query" ref="searchInput" id="header-search-input" :placeholder="$t('header.headerSearch.searchByTitleSubtitleAuthorOrISBN')">
            <i class="material-icons search"/>
        </form>
        <transition name="fade" mode="out-in">
            <div v-if="this.query.trim().length" class="widget widget-featured-products" key="fast-search-results">
                <transition name="fade" mode="out-in">
                    <APILoading :icon-size='40' :font-size='20' key="loading-results"
                                :text="$t('shared.apiLoading.search')" v-if="results === null"/>
                    <div id='results' key="results" v-else-if="results">
                        <h5 v-html="$tc('header.headerSearch.resultsByTitleSubtitleAuthorOrISBNForSearch', this.results.length, { count: this.results.length, search: this.query })"
                            class="text-center mt-3 mb-3">
                        </h5>
                        <hr class="mb-3"/>
                        <div v-if="!results.length" class="text-center">
                            <img alt="No result" id='noResult' :src='noDataSVG'/>
                            <p class="text-muted font-italic mb-0" v-html="$t('HeaderSearch.needMoreSearchCriteria')"/>
                            <a @click.prevent="openAdvancedSearch" href="#"
                               v-html="$t('HeaderSearch.tryAdvancedSearchInShopForMoreResults')"/>
                        </div>
                        <HeaderSearchResults v-for="product in results" :product="product" :search='query'
                                             :key="product._id" @product-selected="productSelected"/>
                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderSearchResults from "./HeaderSearchResults";
import APILoading from "../../../shared/Misc/APILoading";
import { importSVG } from "@/helpers/file";
import Product from "@/classes/Product";

export default {
    name: "HeaderSearch",
    components: {APILoading, HeaderSearchResults},
    data() {
        return {
            query: "",
            results: null,
            totalResults: 0,
        };
    },
    computed: {
        ...mapGetters("config", {
            doesShopShowOnlyPODProducts: "doesShopShowOnlyPODProducts",
            hermesAPIConfig: "hermesAPI",
        }),
        ...mapGetters("sideBarMenu", {
            isSearchSectionActive: "isSearchSectionActive",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
        }),
        noDataSVG() {
            return importSVG("no_data", this.hermesAPIConfig.client);
        },
    },
    methods: {
        ...mapActions("sideBarMenu", {
            hideSideBar: "hide",
        }),
        async search() {
            if (this.query.trim().length) {
                const save = this.query;
                this.results = null;
                try {
                    const user = this.isUserLogged ? this.user._id : null;
                    const titleQuery = { title: this.query.trim(), user, currency: this.chosenCurrency, active: true, "per-page": 10 };
                    const subtitleQuery = { subtitle: this.query.trim(), user, currency: this.chosenCurrency, active: true, "per-page": 10 };
                    const isbnQuery = { isbn: this.query.trim(), user, currency: this.chosenCurrency, active: true, "per-page": 10 };
                    const authorQuery = { author: this.query.trim(), user, currency: this.chosenCurrency, active: true, "per-page": 10 };
                    if (this.doesShopShowOnlyPODProducts) {
                        titleQuery.pod = true;
                        subtitleQuery.pod = true;
                        isbnQuery.pod = true;
                        authorQuery.pod = true;
                    }
                    const responses = await Promise.all([
                        this.$hermesAPI.getProducts(titleQuery),
                        this.$hermesAPI.getProducts(subtitleQuery),
                        this.$hermesAPI.getProducts(isbnQuery),
                        this.$hermesAPI.getProducts(authorQuery),
                    ]);
                    this.totalResults = responses.reduce((acc, response) => acc + response.data.pagination.total, 0);
                    if (save === this.query) {
                        this.results = [].concat(...responses.map(response => response.data.products.map(p => new Product(p))));
                    }
                } catch (err) {
                    this.$error.displayError(err);
                }
            }
        },
        focus(interval) {
            setTimeout(() => {
                this.$refs.searchInput.focus();
            }, interval);
        },
        productSelected() {
            this.query = "";
            this.hideSideBar();
        },
        openAdvancedSearch() {
            this.$router.push({ path: "/shop", query: { modal: "advanced-search" } });
            this.hideSideBar();
        },
    },
    watch: {
        isSearchSectionActive(active) {
            if (active) {
                this.focus(200);
            }
        },
        query() {
            this.search();
        },
        chosenCurrency() {
            this.search();
        },
        isUserLogged() {
            this.search();
        },
    },
};
</script>

<style lang='scss' scoped>
    .widget-featured-products {
        padding-top: 0px !important;
    }

    .widget-title {
        margin-top: 20px;
    }

    #noResult {
        width: 50%;
    }

    #advanced-search-disclaimer {
        font-size: 0.75rem;
    }

    #header-search-input::placeholder {
        font-size: 0.60rem;
    }
</style>