<template>
    <div class="col-lg-3 col-md-4 col-xs-12 order-md-1">
        <transition name="fade" mode="out-in">
            <aside key="menu" class="sidebar sidebar-offcanvas position-left">
                <span class="sidebar-close">
                    <i class="material-icons icon_close"/>
                </span>
                <button id="advanced-search-btn" @click="$emit('show-advanced-search-modal')" class="btn btn-primary btn-block">
                    {{ $t("shop.advancedSearch") }}
                    <i id="search-icon" class="fa fa-search fa-pull-right"/>
                </button>
                <ShopMenuSortBy v-if="frontConfig.areShopSortersEnabled(user)" :queries="queries" @change-query-param="changeQueryParam"/>
                <ShopMenuPublishersFilter v-if="frontConfig.isShopPublishersSideFilterEnabled" :queries="queries" @change-query-param="changeQueryParam"/>
                <ShopMenuBookCollectionsFilter v-if="frontConfig.isShopBookCollectionsSideFilterEnabled"
                                            :book-collections="bookCollections" :queries="queries"
                                            :chosen-publisher="chosenPublisher" @change-query-param="changeQueryParam"/>
            </aside>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShopMenuPublishersFilter from "./ShopMenuPublishersFilter/ShopMenuPublishersFilter";
import ShopMenuBookCollectionsFilter from "./ShopMenuBookCollectionsFilter/ShopMenuBookCollectionsFilter";
import ShopMenuSortBy from "./ShopMenuSortBy";

export default {
    name: "ShopMenu",
    components: {ShopMenuSortBy, ShopMenuBookCollectionsFilter, ShopMenuPublishersFilter},
    props: {
        queries: {
            type: Object,
            required: true,
        },
        bookCollections: {
            required: true,
        },
        chosenPublisher: {
            required: true,
        },
    },
    computed: {
        ...mapGetters("config", {
            frontConfig: "front",
        }),
        ...mapGetters("user", {
            user: "user",
        }),
    },
    methods: {
        changeQueryParam(queryParam) {
            this.$emit("change-query-param", queryParam);
        },
    },
};
</script>

<style scoped lang="scss">
    .custom-control {
        padding-left: 0 !important;
        a {
            text-decoration: none;
            cursor: pointer;
        }
    }

    li.active {
        font-weight: 600 !important;
    }

    #search-icon {
        margin-top: 13px;
    }
</style>