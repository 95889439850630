import { render, staticRenderFns } from "./CheckoutReview.vue?vue&type=template&id=06f4f9af&scoped=true&"
import script from "./CheckoutReview.vue?vue&type=script&lang=js&"
export * from "./CheckoutReview.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutReview.vue?vue&type=style&index=0&id=06f4f9af&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f4f9af",
  null
  
)

export default component.exports