<template>
    <div id="contact-default">
        <div id="contact-informations" class="row justify-content-center align-items-center padding-bottom-2x mb-2">
            <div class="row col-12">
                <div class="col-12 display-3 text-center">
                    <i class="fa fa-headset mr-3 text-primary"/>
                    <span v-html="$t('Contact.customerService')"/>
                    <hr class="mt-3 mb-3"/>
                </div>
            </div>
            <div class="col-2 text-center">
                <img alt="BusinessMan" :src="businessManSVG">
            </div>
            <div class="col-8 text-center">
                <ul id="contact-list" class="list-icon">
                    <li v-if="shop.mail">
                        <span class="fa fa-envelope text-muted mr-2"/>
                        <a class="navi-link" target="_blank" :href="`mailto:${shop.mail}`" v-html="shop.mail"/>
                    </li>
                    <li v-if="shop.phone">
                        <span class="fa fa-phone text-muted mr-2"/>
                        <span v-html="shop.phone"/>
                    </li>
                </ul>
            </div>
            <div class="col-2"/>
        </div>
        <div id="contact-send-mail" v-if="shop.mail">
            <ValidationObserver ref="FormObserver" v-slot="{ invalid }">
                <form @submit.prevent="sendMail">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="display-3 text-center">
                                <i class="fab fa-telegram-plane mr-3 text-primary"/>
                                <span v-html="$t('Contact.sendMail')"/>
                            </div>
                            <hr class="mt-3 mb-3"/>
                            <div class="row">
                                <div class="col-4">
                                    <ValidationProvider ref="senderNameValidationProvider" v-slot="{ errors, classes }">
                                        <div class="form-group">
                                            <label for="send-mail-sender-name">
                                                <span v-html="$t('ContactDefault.yourName')"/>
                                                <RedAsterisk/>
                                            </label>
                                            <input :placeholder="$t('ContactDefault.required')" class="form-control" id="send-mail-sender-name"
                                                   v-model="sender.name" required :class="classes" :disabled="loading">
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-4">
                                    <ValidationProvider ref="senderMailValidationProvider" v-slot="{ errors, classes }">
                                        <div class="form-group">
                                            <label for="send-mail-sender-mail">
                                                <span v-html="$t('ContactDefault.yourMail')"/>
                                                <RedAsterisk/>
                                            </label>
                                            <input :placeholder="$t('ContactDefault.required')" class="form-control" id="send-mail-sender-mail"
                                                   v-model="sender.mail" type="email" required :class="classes" :disabled="loading">
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-4">
                                    <ValidationProvider v-slot="{ errors, classes }">
                                        <div class="form-group">
                                            <label for="send-mail-sender-phone">
                                                <span v-html="$t('ContactDefault.yourPhone')"/>
                                            </label>
                                            <input :placeholder="$t('ContactDefault.optional')" class="form-control" id="send-mail-sender-phone"
                                                   v-model="sender.phone" type="tel" :class="classes" :disabled="loading">
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <ValidationProvider ref="mailSubjectValidationProvider" v-slot="{ errors, classes }">
                                        <div class="form-group">
                                            <label for="send-mail-mail-subject">
                                                <span v-html="$t('ContactDefault.mailSubject')"/>
                                                <RedAsterisk/>
                                            </label>
                                            <input :placeholder="$t('ContactDefault.required')" class="form-control" id="send-mail-mail-subject"
                                                   v-model="mail.subject" required :class="classes" :disabled="loading">
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider ref="mailContentValidationProvider" v-slot="{ errors, classes }">
                                        <div class="form-group">
                                            <label for="send-mail-mail-content">
                                                <span v-html="$t('ContactDefault.mailContent')"/>
                                                <RedAsterisk/>
                                            </label>
                                            <textarea :placeholder="$t('ContactDefault.required')" class="form-control" id="send-mail-mail-content"
                                                      v-model="mail.content" required :class="classes" rows="5" :disabled="loading"/>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12 text-center">
                                    <SubmitBtn :text="$t('ContactDefault.sendMail')" :loading="loading" :disabled="invalid"
                                               classes="btn btn-primary"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import RedAsterisk from "../../shared/Forms/RedAsterisk";
import SubmitBtn from "../../shared/Misc/SubmitBtn";
import { importSVG } from "@/helpers/file";

export default {
    name: "ContactDefault",
    components: { SubmitBtn, RedAsterisk },
    data() {
        return {
            sender: {
                name: undefined,
                mail: undefined,
                phone: undefined,
            },
            mail: {
                subject: undefined,
                content: undefined,
            },
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shop: "shop",
            hermesAPIConfig: "hermesAPI",
        }),
        ...mapGetters("user", {
            user: "user",
            userFullName: "fullName",
            isUserLogged: "isLogged",
        }),
        businessManSVG() {
            return importSVG("businessman", this.hermesAPIConfig.client);
        },
    },
    async mounted() {
        if (this.isUserLogged) {
            this.sender.name = this.userFullName;
            this.sender.mail = this.user.email;
            await this.$nextTick();
            this.$refs.senderNameValidationProvider.validate();
            this.$refs.senderMailValidationProvider.validate();
        }
    },
    methods: {
        getMailData() {
            const senderPhone = this.sender.phone ? this.sender.phone : "Non précisé";
            return {
                subject: this.mail.subject,
                from: {
                    mail: this.shop.mail,
                    name: this.sender.mail,
                },
                to: this.shop.mail,
                header: { show: false },
                body: { content: `Client: ${this.sender.name}<br/>Mail: ${this.sender.mail}<br/>Téléphone: ${senderPhone}<hr/>${this.mail.content}` },
                footer: { show: false },
            };
        },
        async sendMail() {
            try {
                this.loading = true;
                await this.$ohMyMailAPI.sendMail(this.getMailData());
                this.$toasted.success(this.$t("ContactDefault.mailSent"), { icon: "check" });
                this.mail.subject = "";
                this.mail.content = "";
                this.$refs.mailSubjectValidationProvider.reset();
                this.$refs.mailContentValidationProvider.reset();
            } catch (e) {
                this.$error.displayError(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
    #contact-list {
        font-size: 1.5rem;
    }
</style>