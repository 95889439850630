export function inProfileSection(routeName) {
    return routeName === "me" || routeName === "profileOrders" ||
        routeName === "profileAddresses" || routeName === "profileChangePassword" ||
        routeName ==="profilePreferences";
}

export function inHelpSection(routeName) {
    return routeName === "helpAll" || routeName === "helpShop" ||
        routeName === "helpProfile" || routeName === "helpOrders" ||
        routeName === "helpOther" || routeName === "about" ||
        routeName === "termsAndConditions";
}

export function inFAQSection(routeName) {
    return routeName === "helpAll" || routeName === "helpShop" ||
        routeName === "helpProfile" || routeName === "helpOrders" ||
        routeName === "helpOther";
}

export function inShopSection(routeName) {
    return routeName === "shop" || routeName === "product";
}

export function inCheckoutSection(routeName) {
    return routeName === "checkoutBilling" || routeName === "checkoutShipping" ||
        routeName === "checkoutReview" || routeName === "checkoutPayment";
}

export function inSelfPublishingSection(routeName) {
    return routeName === "selfPublishingBooks" || routeName === "selfPublishingCreateBook" || routeName === "selfPublishingFlowQuote" ||
        routeName === "selfPublishingFlowMetadata" || routeName === "productCreated";
}

export function inSelfPublishingFlow(routeName) {
    return routeName === "selfPublishingCreateBook" || routeName === "selfPublishingFlowQuote" ||
        routeName === "selfPublishingFlowMetadata" || routeName === "productCreated";
}