<template>
    <div id="self-publishing-books">
        <div class="padding-top-2x mt-2 hidden-lg-up"></div>
        <transition name="fade" mode="out-in">
            <div v-if="!isUserLogged" key="not-connected" class="text-center">
                <h4 v-html="$t('SelfPublishingBooks.pleaseLogInToGetSelfPublishedBook')"/>
                <button @click="openAccountSection" class="btn btn-primary" v-html="$t('SelfPublishingBooks.logIn')"/>
            </div>
            <div v-else key="my-books">
                <transition name="fade" mode="out-in">
                    <div key="loading" id="loading" class="row mb-2 d-flex justify-content-center"
                         v-if="this.products === null">
                        <APILoading :font-size='30' :text="$t('shared.apiLoading.getProducts')"/>
                    </div>
                    <div class="text-center" v-else-if="this.products && !this.products.length">
                        <h2 v-html="$t('SelfPublishingBooks.noSelfPublishedBook')"/>
                        <router-link to="/self-publishing/" class="btn btn-primary mb-4">
                            <i class="fa fa-pen-fancy mr-2"/>
                            <span v-html="$t('SelfPublishingBooks.publishMyFirstBook')"/>
                        </router-link>
                    </div>
                    <div key="books" v-else>
                        <v-client-table id="my-books" :data="products" :columns="columns" :options="options">
                            <router-link slot="title" slot-scope="props" :to="`/product/${props.row._id}`" target="_blank"
                                         v-html="props.row.title" width="20%"/>
                            <div slot="filesStatus" slot-scope="props" class="text-center">
                                <ProductFileStatusBadge :product="props.row" book-part="corpus" class="mb-2 w-100"/>
                                <ProductFileStatusBadge :product="props.row" book-part="cover" class="w-100"/>
                            </div>
                            <div slot="createdAt" slot-scope="props" v-html="properDate(props.row.createdAt)"/>
                            <div slot="actions" slot-scope="props">
                                <UploadProductFilesButton :product="props.row" class="btn btn-sm btn-block"
                                                          @show-upload-product-files-modal="showUploadProductFilesModal">
                                    <template v-slot:text>
                                        <i class="fa fa-cloud-upload-alt mr-2"/>
                                        <span v-html="$t('SelfPublishingBooks.products.sendFiles')"/>
                                    </template>
                                </UploadProductFilesButton>
                                <ApprovePrintingBtn v-if="props.row.waitingForUserPrintingApproval" :product="props.row"
                                                    @update-product="updateProduct"/>
                                <AddToCartBtn v-if="props.row.canAddToCart(isUserLogged, doesShopShowOnlyPODProducts, cannotOrderUnprintableTwice)"
                                              :product="props.row"
                                              :text="`<i class='fa fa-cart-arrow-down mr-2'></i> ${$t('SelfPublishingBooks.addToCart')}`"
                                              classes="btn btn-primary btn-block btn-sm"/>
                            </div>
                        </v-client-table>
                    </div>
                </transition>
            </div>
        </transition>
        <UploadProductFilesModal ref="uploadProductFilesModal" @update-product="updateProduct"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { decodeJWTToken } from "@/helpers/API";
import Product from "@/classes/Product";
import APILoading from "../../../shared/Misc/APILoading";
import { properDate } from "@/helpers/date";
import ProductFileStatusBadge from "../../../shared/Product/ProductFiles/ProductFileStatusBadge";
import UploadProductFilesModal from "../../../shared/Product/ProductFiles/UploadProductFilesModal";
import UploadProductFilesButton from "../../../shared/Product/ProductFiles/UploadProductFilesButton";
import AddToCartBtn from "@/components/shared/Product/AddToCartBtn";
import ApprovePrintingBtn from "@/components/Hermes/SelfPublishing/SelfPublishingBooks/ApprovePrintingBtn";

export default {
    name: "SelfPublishingBooks",
    components: {
        ApprovePrintingBtn,
        AddToCartBtn,
        UploadProductFilesButton,
        UploadProductFilesModal,
        ProductFileStatusBadge,
        APILoading,
    },
    data() {
        return {
            products: null,
            columns: ["title", "author", "filesStatus", "createdAt", "actions"],
            options: {
                headings: {
                    title: this.$t("SelfPublishingBooks.products.title"),
                    author: this.$t("SelfPublishingBooks.products.author"),
                    filesStatus: this.$t("SelfPublishingBooks.products.files"),
                    createdAt: this.$t("SelfPublishingBooks.products.createdAt"),
                    actions: this.$t("SelfPublishingBooks.products.actions"),
                },
                perPage: 10,
                sortable: ["title", "author", "filesStatus", "createdAt"],
                filterable: ["title", "author", "filesStatus", "createdAt"],
                orderBy: {
                    ascending: false,
                    column: "createdAt",
                },
            },
        };
    },
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            doesShopShowOnlyPODProducts: "doesShopShowOnlyPODProducts",
            cannotOrderUnprintableTwice: "cannotOrderUnprintableTwice",
        }),
    },
    created() {
        if (this.isUserLogged) {
            this.getSelfPublishedBooks();
        }
    },
    methods: {
        ...mapActions("sideBarMenu", {
            openAccountSection: "openAccountSection",
        }),
        async getSelfPublishedBooks() {
            try {
                const { userId } = decodeJWTToken();
                const { data } = await this.$hermesAPI.getProducts({
                    source: "user",
                    "self-publisher": userId,
                    "per-page": 0,
                    user: userId,
                });
                this.products = data.products.map(product => new Product(product));
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        showUploadProductFilesModal(product) {
            this.$refs.uploadProductFilesModal.show(product);
        },
        updateProduct(updatedProduct) {
            const idx = this.products.findIndex(({ _id }) => _id === updatedProduct._id);
            if (idx !== -1) {
                this.products.splice(idx, 1, new Product(updatedProduct));
            }
        },
        properDate,
    },
    watch: {
        isUserLogged(value) {
            if (value) {
                this.getSelfPublishedBooks();
            }
        },
    },
};
</script>

<style lang="scss">
    #my-books {
        tr td:nth-child(1) {
            width: 25%;
        }
        tr td:nth-child(3) {
            width: 20%;
        }
        tr td:nth-child(5) {
            width: 15%;
        }
    }
</style>