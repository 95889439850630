<template>
    <div>
        <h3 class="text-success">
            <i class="far fa-check-circle"></i>
            {{$t("header.account.connected")}}
        </h3>
        <ChangeUser v-if="decodedToken.isAdmin"/>
        <br/>
        <img alt="Connected" :src="connectedSVG"/>
        <br/>
        <span>{{$t("header.account.rights")}}</span>
        <br/>
        <router-link v-if="frontConfig.isShopSectionEnabled" @click.native="hideSideBar" class="btn btn-warning" to="/shop">
            <i class="fa fa-shopping-bag mr-2"></i>
            {{$t("header.account.accessShop")}}
        </router-link>
        <br/>
        <router-link @click.native="hideSideBar" class="btn btn-primary" to="/profile">
            <i class="fa fa-user mr-2"></i>
            {{$t("header.account.accessProfile")}}
        </router-link>
        <br/>
        <button class="btn btn-outline-secondary" @click="logout">{{$t("header.account.logout")}}</button>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ChangeUser from "./ChangeUser";
import { importSVG } from "../../../../helpers/file";
import { decodeJWTToken } from "../../../../helpers/API";

export default {
    name: "Connected",
    components: { ChangeUser },
    data() {
        return {
            decodedToken: decodeJWTToken(),
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
            frontConfig: "front",
        }),
        connectedSVG() {
            return importSVG("connected", this.hermesAPIConfig.client);
        },
    },
    methods: {
        ...mapActions("sideBarMenu", {
            hideSideBar: "hide",
        }),
        logout() {
            this.$store.dispatch("user/logout");
        },
    },
};
</script>

<style scoped>

</style>