<template>
    <div>
        <HelpShop v-if="frontConfig.isShopSectionEnabled" class="mb-3"/>
        <HelpProfile class="mb-3"/>
        <HelpOrders class="mb-3"/>
        <HelpOther/>
    </div>
</template>

<script>
import HelpProfile from "./HelpProfile";
import HelpShop from "./HelpShop";
import HelpOrders from "./HelpOrders";
import HelpOther from "./HelpOther";
import {mapGetters} from "vuex";
export default {
    name: "HelpAll",
    components: {HelpOther, HelpOrders, HelpShop, HelpProfile},
    computed: {
        ...mapGetters("config", {
            frontConfig: "front",
        }),
    },
};
</script>

<style scoped>

</style>