<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-7">
                <div class="display-3 text-muted opacity-75 mb-30">ILP DAKAR</div>
            </div>
            <div class="col-md-5">
                <ul class="list-icon">
                    <li> <i class="material-icons home text-muted"></i>10 VDN SICAP AMITIE 3 CITE POLICE</li>
                    <li> <i class="material-icons mail_outline text-muted"></i><a class="navi-link" href="mailto:info@ilpdakar.com">info@ilpdakar.com</a></li>
                    <li> <i class="material-icons phone text-muted"></i>+221 33 825 98 54</li>
                </ul>
            </div>
        </div>
        <hr/>
        <div class="row mt-4">
            <div class="col-md-7">
                <div class="display-3 text-muted opacity-75 mb-30">ILP PARIS</div>
            </div>
            <div class="col-md-5">
                <ul class="list-icon">
                    <li> <i class="material-icons home text-muted"></i>68 avenue des Guilleraies – 92000 Nanterre</li>
                    <li> <i class="material-icons mail_outline text-muted"></i><a class="navi-link" href="mailto:info@ilpgroupe.com">info@ilpgroupe.com</a></li>
                    <li> <i class="material-icons phone text-muted"></i>+33 1 56 83 72 37</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactAddressILP",
};
</script>

<style scoped>

</style>