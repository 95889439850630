import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vSelect from "vue-select";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import HermesAPI from "./plugins/HermesAPI";
import DiceAPI from "./plugins/DiceAPI";
import SoBookQuotationAPI from "./plugins/SoBookQuotationAPI";
import MonSoBookAPI from "./plugins/MonSoBookAPI";
import OhMyMailAPI from "./plugins/OhMyMailAPI";
import Error from "./plugins/Error";
import VueScrollTo from "vue-scrollto";
import VueLazyLoad from "vue-lazyload";
import Toasted from "vue-toasted";
import VTooltip from "v-tooltip";
import { ClientTable } from "vue-tables-2";
import VueHeadFul from "vue-headful";
import StarRating from "vue-star-rating";
import VueTour from "vue-tour";
import ReadMore from "vue-read-more";
import fr from "./i18n/fr";
import Config from "../config";
import i18n from "./i18n";
import "./vee-validate";

import "vue-select/src/scss/vue-select.scss";
import "vue-tour/dist/vue-tour.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/iziToast.min.css";
import "./assets/css/material-icons.min.css";
import "./assets/css/photoswipe.min.css";
import "./assets/css/socicon.min.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
import "glyphicons-only-bootstrap/css/bootstrap.min.css";
import "./assets/scss/all/custom.scss";
import "./assets/scss/all/font-awesome.scss";
import "./assets/scss/all/transition.scss";
import "./assets/scss/all/vue-lazyload.scss";
import "./assets/scss/all/v-tooltip.scss";
import "./assets/scss/all/vue-select.scss";
import "./assets/scss/all/vue-toasted.scss";
import "./assets/scss/all/sweetalert2.scss";
import "./assets/scss/all/medium-zoom.scss";
import "./assets/scss/all/vue-tour.scss";
import loadingCover from "./assets/gif/loading-picture.gif";

import noCover from "./assets/img/no-cover.png";

Vue.config.productionTip = false;
Vue.component("v-select", vSelect);
Vue.component("vue-headful", VueHeadFul);
Vue.component("star-rating", StarRating);
Vue.use(VueScrollTo, {
    offset: -150,
});
Vue.use(VueLazyLoad, { error: noCover, loading: loadingCover, attempt: 1 });
Vue.use(VTooltip, { container: "body", popover: { defaultContainer: "body", defaultPlacement: "top", defaultWrapperClass: "popover-wrapper" } });
Vue.use(Toasted, {
    position: "bottom-right",
    duration: 5000,
    iconPack: "fontawesome",
    router,
});
Vue.use(ClientTable, { texts: fr.VueClientTable }, false, require("./assets/js/vue-table-2/vue-table-2-custom-theme"));
Vue.use(VueTour);
Vue.use(ReadMore);

if (Config.sentry.enabled) {
    Sentry.init({
        dsn: `https://${Config.sentry.key}@sentry.io/${Config.sentry.projectId}`,
        integrations: [new Integrations.Vue({Vue, attachProps: true})],
    });
}
Vue.use(HermesAPI, { token: localStorage.getItem("token") });
Vue.use(DiceAPI);
Vue.use(SoBookQuotationAPI);
Vue.use(MonSoBookAPI);
Vue.use(OhMyMailAPI);
Vue.use(Error, { router });

import store from "./store";
new Vue({
    router,
    i18n,
    store,
    render: h => {
        require("../public/js/scripts");
        return h(App);
    },
}).$mount("#app");