<template>
    <div id="about">
        <PageTitle :title="$t('about.title')">
            <template v-slot:left>
                <i class="fa fa-question-circle fa-3x text-primary"/>
            </template>
        </PageTitle>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-sm-2">
                    <img alt="Questions" :src="questionsSVG">
                </div>
            </div>
        </div>
        <AboutILP v-if="hermesAPIConfig.client === 'ilp'"/>
        <AboutGutenberg v-else-if="hermesAPIConfig.client === 'gutenberg'"/>
        <AboutLaBoutiqueSoBook v-else-if="hermesAPIConfig.client === 'la-boutique-sobook'"/>
        <AboutImprimerVosLivres v-else-if="hermesAPIConfig.client === 'imprimer-vos-livres'"/>
        <DefaultAbout v-else/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../shared/Misc/PageTitle";
import AboutILP from "./AboutILP/AboutILP";
import { importSVG } from "../../../helpers/file";
import DefaultAbout from "./DefaultAbout/DefaultAbout";
import AboutGutenberg from "./AboutGutenberg/AboutGutenberg";
import AboutLaBoutiqueSoBook from "./AboutLaBoutiqueSoBook/AboutLaBoutiqueSoBook";
import AboutImprimerVosLivres from "@/components/Hermes/About/AboutImprimerVosLivres/AboutImprimerVosLivres";

export default {
    name: "About",
    components: {AboutImprimerVosLivres, AboutLaBoutiqueSoBook, AboutGutenberg, DefaultAbout, AboutILP, PageTitle},
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        questionsSVG() {
            return importSVG("questions", this.hermesAPIConfig.client);
        },
    },
};

</script>

<style scoped>

</style>