import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Hermes/Home/Home";
import About from "./components/Hermes/About/About";
import Cart from "./components/Hermes/Cart/Cart";
import Checkout from "./components/Hermes/Checkout/Checkout";
import CheckoutBilling from "./components/Hermes/Checkout/CheckoutBilling";
import CheckoutShipping from "./components/Hermes/Checkout/CheckoutShipping/CheckoutShipping";
import CheckoutPayment from "./components/Hermes/Checkout/CheckoutPayment/CheckoutPayment";
import CheckoutReview from "./components/Hermes/Checkout/CheckoutReview/CheckoutReview";
import Contact from "./components/Hermes/Contact/Contact";
import Product from "./components/Hermes/Product/Product";
import Shop from "./components/Hermes/Shop/Shop";
import Profile from "./components/Hermes/Profile/Profile";
import Me from "./components/Hermes/Profile/Me";
import ProfileOrders from "./components/Hermes/Profile/ProfileOrders/ProfileOrders";
import ProfileAddresses from "./components/Hermes/Profile/ProfileAddresses";
import NotFound from "./components/Hermes/NotFound/NotFound";
import CheckoutComplete from "./components/Hermes/CheckoutComplete/CheckoutComplete";
import TermsAndConditions from "./components/Hermes/TermsAndConditions/TermsAndConditions";
import ResetPassword from "./components/Hermes/ResetPassword/ResetPassword";
import ProfileChangePassword from "./components/Hermes/Profile/ProfileChangePassword";
import Help from "./components/Hermes/Help/Help";
import HelpShop from "./components/Hermes/Help/HelpShop";
import HelpProfile from "./components/Hermes/Help/HelpProfile";
import HelpOrders from "./components/Hermes/Help/HelpOrders";
import HelpOther from "./components/Hermes/Help/HelpOther";
import HelpAll from "./components/Hermes/Help/HelpAll";
import ProfilePreferences from "./components/Hermes/Profile/ProfilePreferences/ProfilePreferences";
import SelfPublishing from "./components/Hermes/SelfPublishing/SelfPublishing";
import SelfPublishingBooks from "./components/Hermes/SelfPublishing/SelfPublishingBooks/SelfPublishingBooks";
import SelfPublishingCreateBook
    from "./components/Hermes/SelfPublishing/SelfPublishingCreateBook/SelfPublishingCreateBook";
import SelfPublishingFlowQuote
    from "./components/Hermes/SelfPublishing/SelfPublishingCreateBook/SelfPublishingFlow/SelfPublishingFlowQuote";
import SelfPublishingFlowMetadata
    from "./components/Hermes/SelfPublishing/SelfPublishingCreateBook/SelfPublishingFlow/SelfPublishingFlowMetadata";
import ProductCreated from "./components/Hermes/ProductCreated/ProductCreated";

Vue.use(Router);

// TODO: Retirer quand le push ne bugera plus au même niveau URL
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
};

// TODO: Retirer quand le replace ne bugera plus au même niveau URL
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(query, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalReplace.call(this, query, onResolve, onReject);
    }
    return originalReplace.call(this, query).catch(err => err);
};

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
        }, {
            path: "/about",
            name: "about",
            component: About,
        }, {
            path: "/terms-and-conditions",
            name: "termsAndConditions",
            component: TermsAndConditions,
        }, {
            path: "/cart",
            name: "cart",
            component: Cart,
        }, {
            path: "/checkout",
            component: Checkout,
            children: [
                {
                    path: "",
                    name: "checkoutBilling",
                    component: CheckoutBilling,
                }, {
                    path: "shipping",
                    name: "checkoutShipping",
                    component: CheckoutShipping,
                }, {
                    path: "payment",
                    name: "checkoutPayment",
                    component: CheckoutPayment,
                }, {
                    path: "review",
                    name: "checkoutReview",
                    component: CheckoutReview,
                },
            ],
        }, {
            path: "/checkout-complete",
            name: "checkoutComplete",
            component: CheckoutComplete,
        }, {
            path: "/contact",
            name: "contact",
            component: Contact,
        }, {
            path: "/profile",
            component: Profile,
            children: [
                {
                    path: "",
                    name: "me",
                    component: Me,
                }, {
                    path: "orders",
                    name: "profileOrders",
                    component: ProfileOrders,
                }, {
                    path: "addresses",
                    name: "profileAddresses",
                    component: ProfileAddresses,
                }, {
                    path: "preferences",
                    name: "profilePreferences",
                    component: ProfilePreferences,
                }, {
                    path: "change-password",
                    name: "profileChangePassword",
                    component: ProfileChangePassword,
                },
            ],
        }, {
            path: "/self-publishing",
            component: SelfPublishing,
            redirect: "/self-publishing/create-book",
            children: [
                {
                    path: "books",
                    name: "selfPublishingBooks",
                    component: SelfPublishingBooks,
                }, {
                    path: "create-book",
                    component: SelfPublishingCreateBook,
                    children: [
                        {
                            path: "",
                            name: "selfPublishingFlowQuote",
                            component: SelfPublishingFlowQuote,
                        }, {
                            path: "metadata",
                            name: "selfPublishingFlowMetadata",
                            component: SelfPublishingFlowMetadata,
                        },
                    ],
                },
            ],
        }, {
            path: "/product-created/:id",
            name: "productCreated",
            component: ProductCreated,
        }, {
            path: "/shop",
            name: "shop",
            component: Shop,
        }, {
            path: "/product/:id/:title?",
            name: "product",
            component: Product,
        }, {
            path: "/reset-password",
            name: "reset-password",
            component: ResetPassword,
        }, {
            path: "/help",
            component: Help,
            children: [
                {
                    path: "",
                    name: "helpAll",
                    component: HelpAll,
                }, {
                    path: "shop",
                    name: "helpShop",
                    component: HelpShop,
                }, {
                    path: "profile",
                    name: "helpProfile",
                    component: HelpProfile,
                }, {
                    path: "orders",
                    name: "helpOrders",
                    component: HelpOrders,
                }, {
                    path: "other",
                    name: "helpOther",
                    component: HelpOther,
                },
            ],
        }, {
            path: "/404",
            component: NotFound,
        }, {
            path: "*",
            redirect: "/404",
        },
    ],
    scrollBehavior() {
        try {
            window.scrollTo({ top: 0, behavior: "smooth" });
        } catch (e) {
            window.scrollTo(0, 0);
        }
    },
});

router.afterEach(() => {
    // eslint-disable-next-line no-undef
    $(".modal").modal("hide");
});

export default router;