<template>
    <div id="self-publishing-flow">
        <SelfPublishingFlowNav ref="SelfPublishingFlowNav"/>
        <router-view :self-published-book="selfPublishedBook" :loading-create-product="loadingCreateProduct"
                     @fill-book-components="fillBookComponents" @create-book="createBook" class="fadeIn"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "../../../../../classes/Product";
import SelfPublishingFlowNav from "./SelfPublishingFlowNav";

export default {
    name: "SelfPublishingFlow",
    components: { SelfPublishingFlowNav },
    props: {
        selfPublishedBook: {
            type: Product,
            required: true,
        },
    },
    data() {
        return {
            loadingCreateProduct: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
    },
    methods: {
        changeStep(stepNumber) {
            this.$refs.SelfPublishingFlowNav.changeStep(stepNumber);
        },
        fillBookComponents(selfPublishedBook) {
            this.$emit("update-self-published-book", { ...this.selfPublishedBook, ...selfPublishedBook });
            this.changeStep(1);
        },
        async createBook({ metadata, addToCart }) {
            try {
                this.loadingCreateProduct = true;
                const book = { ...this.selfPublishedBook, ...metadata };
                book.paging.total = parseInt(book.paging.black) + parseInt(book.paging.color);
                const newProduct = await this.$monSoBookAPI.createProduct({ book, shop: this.hermesAPIConfig.client });
                this.$toasted.success(this.$t("SelfPublishingFlow.productCreated"), { icon: "check" });
                if (addToCart) {
                    await this.$store.dispatch("user/addToCart", { productId: newProduct._id, quantity: book.quantity });
                    this.$toasted.success(this.$t("SelfPublishingFlow.directlyAddedToCart"), { icon: "cart-arrow-down" });
                }
                await this.$router.push(`/product-created/${newProduct._id}`);
            } catch (e) {
                this.$error.displayError(e);
            } finally {
                this.loadingCreateProduct = false;
            }
        },
    },
};
</script>

<style scoped>

</style>