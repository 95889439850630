<template>
    <transition mode="out-in" name="fade">
        <div v-if="users === undefined" key="loading">
            <APILoading :font-size="24" :text="$t('header.account.changeAccount.loadingUsers')"/>
        </div>
        <div v-else key="users" class="col-xs-12">
            <label for="select-user">{{$t("header.account.changeAccount.changeUser")}}</label>
            <v-select id="select-user" v-model="chosenUser" :filter-by="filterBy"
                      :placeholder='$t("header.account.changeAccount.chooseUser")'
                      :options="localUsers" :clearable="false" @change="changeUser">
                <template slot="option" slot-scope="option">
                    {{ option.label }}
                </template>
            </v-select>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import APILoading from "../../../shared/Misc/APILoading";
import User from "@/classes/User";
import { filterBy } from "@/helpers/v-select";
import { decodeJWTToken } from "@/helpers/API";

export default {
    name: "ChangeUser",
    components: {APILoading},
    data() {
        return {
            users: undefined,
            chosenUser: undefined,
            decodedToken: decodeJWTToken(),
        };
    },
    async created() {
        await this.getUsers();
        if (this.users.findIndex(user => user._id === this.decodedToken.userId) === -1) {
            this.decodedToken = decodeJWTToken(true);
            this.chosenUser = {
                value: this.decodedToken.userId,
                label: this.displayUser(this.users.find(user => user._id === this.decodedToken.userId)),
            };
            this.changeUser();
        } else {
            this.chosenUser = {
                value: this.decodedToken.userId,
                label: this.displayUser(this.users.find(user => user._id === this.decodedToken.userId)),
            };
        }
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
        }),
        localUsers() {
            if (!this.users) {
                return [];
            }
            return this.users.map(user => ({ value: user._id, label: this.displayUser(user) }));
        },
    },
    methods: {
        filterBy,
        async getUsers() {
            try {
                const { data: users } = await this.$hermesAPI.getUsers({ active: true });
                this.users = [];
                for (const user of users) {
                    this.users.push(new User(user));
                }
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        displayUser(user) {
            let name = "";
            if (user.society) {
                name += user.society;
                if (user.firstName || user.lastName) {
                    name += ` - ${user.firstName} ${user.lastName}`;
                }
            } else if (user.firstName || user.lastName) {
                name = `${user.firstName} ${user.lastName}`;
            } else {
                name = user.email;
            }
            return name;
        },
        changeUser() {
            if (this.chosenUser && this.user._id !== this.chosenUser.value) {
                this.$store.dispatch("user/changeUser", this.chosenUser.value);
            }
        },
    },
    watch: {
        chosenUser(chosen, previous) {
            if (previous && chosen && chosen.value !== previous.value) {
                this.changeUser();
            }
        },
    },
};
</script>

<style lang="scss">
    #select-user {
        .dropdown-toggle {
            max-height: 60px !important;
        }
    }

</style>