<template>
    <div id="self-publishing-flow-quote-footer-imprimer-vos-livres">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-lg">
                        <i class="fas fa-cog mr-2"/>
                        <span v-html="`${$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.customQuoteOptions')}`"/>
                    </div>
                    <div class="card-body bg-white">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <div class="row justify-content-center mt-3">
                                    <p class="col-10" v-html="`${$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.otherFormats')} ?`"/>
                                </div>
                                <div class="row justify-content-center">
                                    <p class="col-10" v-html="`${$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.otherQuantities')} ?`"/>
                                </div>
                                <div class="row justify-content-center">
                                    <p class="col-10" v-html="`${$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.otherOptions')} ?`"/>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-multi-shipping-addresses"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-multi-shipping-addresses"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.multiShippingAddresses')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-flaps"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-flaps"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.flaps')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-bookmarks"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-bookmarks"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.bookmarks')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-jacket"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-jacket"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.jacket')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-others"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-others"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.others')"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img :src="IMGs.typolibris" width="200" alt="Typolibris Logo"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <a href="https://www.typolibris.fr/nous-contacter/" target="_blank" class="btn btn-primary btn-sm">
                                            <span v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.customQuote')"/>
                                            <i class="fa fa-chevron-right ml-2"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { importPNG } from "@/helpers/file";

export default {
    name: "SelfPublishingFlowQuoteFooterImprimerVosLivres",
    data() {
        return {
            IMGs: {
                typolibris: importPNG("custom-quote/typolibris", "imprimer-vos-livres"),
            },
        };
    },
};
</script>

<style scoped>

</style>