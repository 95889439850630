<template>
    <div class="container">
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <p class="mb-4">Comment donner de la visibilité à plus de 65000 nouveaux livres par an en France? Les librairies, bibliothèques, médiathèques etc., sont des espaces finis qui doivent gérer un catalogue de titres qui augmente de façon exponentielle.</p>
            <p class="mb-4">Avec cette innovation protégée par un brevet mondial, le livre devient en permanence accessible, rematérialisé à la demande, à l’unité devant le lecteur. Il s’agit progressivement de créer une place de marché, qui reposera sur une mise en réseau des « box des livres » dans un processus régulé par les imprimeurs de France au service des lecteurs, des éditeurs, des annonceurs, etc., faisant de ce transporteur de l’information et de la communication qu’est le papier, un média stratégique, dynamique, écologique, démocratique, doué d’ubiquité, à l’atout mémoriel inégalable, dans la tradition d’élargissement de la diffusion de l’écrit voulu par notre maître Johannes Gutenberg…</p>
            <p class="mb-4">GUTENBERG ONE prend sa genèse dans le programme de recherche et développement IRENEO porté par l’IDEP et l’UNIIC, dirigé par Hubert PEDURAND qui entreprit une réflexion de branche quant aux enjeux et à l’avenir du livre imprimé à la demande via le robot Espresso Book Machine (TM). Depuis son lancement en 2014, le programme a poursuivi son dialogue avec l’interprofession du livre (auteurs, éditeurs, libraires, imprimeurs). L’objectif étant d’engager une réflexion collective afin d’imaginer et construire ce que pourrait être le livre imprimé de demain, au plus près du lecteur.</p>
            <p class="mb-4">Après un transfert du programme IRENEO de l’IDEP vers NEOMEDIAS en 2017, NEOMEDIAS continua d’entreprendre jusqu’à inventer et développer son propre robot des livres, présenté en première mondiale au Salon du livre Paris 2019. GUTENBERG ONE propose aux imprimeurs de devenir les spécialistes de la rematérialisation des livres en circuit court, à proximité ou directement dans le point de vente voire dans ses propres ateliers du livre GUTENBERG & Co.</p>
            <p class="mb-4">Cette « box des livres » GUTENBERG ONE de fabrication française, rend disponibles les livres jusque-là indisponibles, partout, tout le temps, tout de suite, garantissant une exploitation permanente et suivie des ouvrages, dans une qualité respectueuse de l’œuvre et des règles de l’art. La diffusion du robot commence dès le 14 mars 2019 à la Porte de Versailles, avec les premières installations prévues en septembre 2019 dans deux villes, Paris et Nevers. Nous ambitionnons d’étendre progressivement notre réseau aux 222 villes inscrites dans le plan Action Cœur de Ville lancé par le Gouvernement, ainsi nous tablons sur une hypothèse de 222 robots avec à la clé, la création de 450 emplois en France.</p>
            <p class="mb-4">GUTENBERG ONE est la première pierre à l’édifice d’une manufacture française de robots dédiée aux industries graphiques, avec en appui un centre technique de branche.</p>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutGutenberg",
};
</script>

<style scoped>

</style>