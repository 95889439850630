<template>
    <div id="terms-and-conditions">
        <PageTitle :title="$t('termsAndConditions.title')">
            <template v-slot:left>
                <i class="fa fa-handshake fa-3x text-primary"/>
            </template>
        </PageTitle>
        <div class="row justify-content-center">
            <div class="col-sm-2">
                <img :src="agreement">
            </div>
        </div>
        <div class="container">
            <section class="row align-items-center padding-top-2x padding-bottom-2x">
                <TermsAndConditionsILP v-if="hermesAPIConfig.client === 'ilp'"/>
                <TermsAndConditionsBoutiqueSoBook v-else-if="hermesAPIConfig.client === 'la-boutique-sobook'"/>
                <TermsAndConditionsImprimerVosLivres v-else-if="hermesAPIConfig.client === 'imprimer-vos-livres'"/>
            </section>
        </div>
    </div>
</template>

<script>
import { importSVG } from "../../../helpers/file";
import PageTitle from "../../shared/Misc/PageTitle";
import TermsAndConditionsILP from "./TermsAndConditionsILP";
import {mapGetters} from "vuex";
import TermsAndConditionsBoutiqueSoBook from "./TermsAndConditionsBoutiqueSoBook";
import TermsAndConditionsImprimerVosLivres from "./TermsAndConditionsImprimerVosLivres";

export default {
    name: "TermsAndConditions",
    components: {TermsAndConditionsImprimerVosLivres, TermsAndConditionsBoutiqueSoBook, TermsAndConditionsILP, PageTitle},
    data() {
        return {
            agreement: importSVG("agreement"),
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
    },
};
</script>

<style scoped>

</style>