<template>
    <div id="checkout-complete-upload-files">
        <PageTitle :title="$t('CheckoutCompleteUploadFiles.uploadMyFiles')">
            <template v-slot:left>
                <i class="fa fa-cloud-upload-alt fa-3x text-primary"/>
            </template>
        </PageTitle>
        <div class="row justify-content-center">
            <div class="col-8">
                <div class="accordion" id="checkout-complete-upload-files-accordion" role="tablist">
                    <div v-for="product in order.selfPublishedProductsWithFilesToUploadOrToCheck" :key="product._id" class="card">
                        <div class="card-header" role="tab">
                            <h6>
                                <a :href="`#product-accordion-${product._id}`" data-toggle="collapse" class="collapsed" aria-expanded="false">
                                    <span v-html="product.title"/>
                                </a>
                            </h6>
                        </div>
                        <div class="collapse" :id="`product-accordion-${product._id}`" data-parent="#checkout-complete-upload-files-accordion" role="tabpanel">
                            <div class="card-body">
                                <UploadProductFiles :product="product" @files-uploaded="updateProduct"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Order from "../../../../classes/Order";
import PageTitle from "../../../shared/Misc/PageTitle";
import UploadProductFiles from "../../../shared/Product/ProductFiles/UploadProductFiles";

export default {
    name: "CheckoutCompleteUploadFiles",
    components: {UploadProductFiles, PageTitle},
    props: {
        order: {
            type: Order,
            required: true,
        },
    },
    methods: {
        updateProduct(updatedProduct) {
            this.$emit("update-product", updatedProduct);
        },
    },
};
</script>

<style scoped>

</style>